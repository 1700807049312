import React from 'react';
import {
  withStyles, Paper, Table, TableRow, TableCell, TableHead, TableBody, Typography, Button, CircularProgress, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Hidden
} from '@material-ui/core';
import { Check as CheckIcon, Edit as EditIcon, Close as CloseIcon, Delete as DeleteIcon, Restaurant as RestaurantIcon, Motorcycle as MotorcycleIcon, TransferWithinAStation as TransferWithinAStationIcon, NavigateBefore as NavigateBeforeIcon, Message as MessageApp } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { apolloClient } from '../apolloClient';
import { getOrdersGql, OrderStatusChip } from './ListOrders';
import { getStatsGql } from './Dashboard';
import { parseGqlError, formatDateTime } from '../utils';
import { actionsBarStyle, tableResponsiveContainer } from '../globalStyles';
import { Spacer, ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    marginBottom: theme.spacing.unit * 3
  },
  actionsBarStyle: actionsBarStyle(theme),
  table: {
    // borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  totalRow: {
    backgroundColor: theme.palette.background.default
  },
  deleteActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  },
  edit: {
    margin: theme.spacing.unit * 3
  },
  tableResponsiveContainer: tableResponsiveContainer(theme)
});

export const getOrderGql = gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id, type, status, tableNumber, custName, custEmail, custPhone, custAddress, createdAt, items {
        id, title, price, quantity
      }
    }
    getRestaurant {
      id, callingCode, currency
    }
  }
`;

class ViewOrder extends React.Component {
  state = {
    orderToDelete: '',
    showDeleteDialog: false,
  }
  changeOrderStatus = async (id, status) => {
    try {
      const changeOrderStatusGql = gql`
        mutation changeOrderStatus($id: ID!) {
          markOrder${status}(id: $id) {
            id, status
          }
        }
      `;
      await apolloClient.mutate({
        mutation: changeOrderStatusGql,
        variables: { id },
        refetchQueries: [{ query: getStatsGql }],
      });
    } catch (err) {
      this.setState({ error: parseGqlError(err) });
    }
  }
  removeOrder = (id) => {
    this.setState({ showDeleteDialog: true, orderToDelete: { id } })
  }
  confirmDelete = async () => {
    const { history } = this.props;
    const { id } = this.state.orderToDelete;
    this.setState({ showDeleteDialog: false, orderToDelete: '' });
    try {
      const removeOrderGql = gql`
        mutation removeOrder($id: ID!) {
          removeOrder(id: $id) {
            id, type
          }
        }
      `;
      await apolloClient.mutate({
        mutation: removeOrderGql,
        variables: { id },
        refetchQueries: [{ query: getStatsGql }],
        update: (proxy, { data: { removeOrder } }) => {
          const { getOrders } = proxy.readQuery({ query: getOrdersGql, variables: { type: removeOrder.type } });
          getOrders.splice(getOrders.findIndex(o => o.id === id), 1);
          proxy.writeQuery({ query: getOrdersGql, variables: { type: removeOrder.type }, data: { getOrders } });
        }
      });
      history.push('/orders/list');
    } catch (err) {
      this.setState({ error: parseGqlError(err) });
    }
  }
  cancelDelete = () => {
    this.setState({ showDeleteDialog: false, orderToDelete: '' })
  }
  genMessage = (order) => {
    if (order.type === 'HOME_DELIVERY' && order.status === 'DISPATCHED') {
      return `Your order number ${order.id} has been dispatched.`;
    }
    if (order.type === 'TAKE_AWAY' && order.status === 'READY') {
      return `Your order number ${order.id} is ready for pickup.`
    }
    return '';
  }
  render() {
    const { classes, history, match } = this.props;
    return (
      <Query query={getOrderGql} variables={{ id: match.params.id }}>
        {({ loading, error, data: { getOrder: order, getRestaurant: restaurant } = {} }) => {
          if (loading) return (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          );
          if (error) return <ErrorMessage error={parseGqlError(error)} />;
          return (
            <>
              <div className={classes.actionsBarStyle}>
                <Tooltip title="Back to Orders"><IconButton onClick={() => history.push('/orders/list')}>
                  <NavigateBeforeIcon fontSize="small" />
                </IconButton></Tooltip>
                {order.status === 'PENDING' && order.type === 'DINE_IN' && <Tooltip title="This order got served"><IconButton onClick={() => this.changeOrderStatus(order.id, 'Served')}>
                  <RestaurantIcon fontSize="small" />
                </IconButton></Tooltip>}
                {order.status === 'PENDING' && order.type === 'TAKE_AWAY' && <Tooltip title="This order is ready for take away"><IconButton onClick={() => this.changeOrderStatus(order.id, 'Ready')}>
                  <TransferWithinAStationIcon fontSize="small" />
                </IconButton></Tooltip>}
                {order.status === 'PENDING' && order.type === 'HOME_DELIVERY' && <Tooltip title="This order is dispatched to user"><IconButton onClick={() => this.changeOrderStatus(order.id, 'Dispatched')}>
                  <MotorcycleIcon fontSize="small" />
                </IconButton></Tooltip>}
                {['SERVED', 'READY', 'DISPATCHED'].includes(order.status) && <Tooltip title="Order got completed successfully"><IconButton onClick={() => this.changeOrderStatus(order.id, 'Complete')}>
                  <CheckIcon fontSize="small" />
                </IconButton></Tooltip>}
                {!['COMPLETE', 'CANCELED'].includes(order.status) && <Tooltip title="Cancel this order"><IconButton onClick={() => this.changeOrderStatus(order.id, 'Canceled')}>
                  <CloseIcon fontSize="small" />
                </IconButton></Tooltip>}
                <Tooltip title="Edit Order"><IconButton onClick={() => history.push(`/orders/edit/${order.id}`)}>
                  <EditIcon fontSize="small" />
                </IconButton></Tooltip>
                <Tooltip title="Delete Order"><IconButton onClick={() => this.removeOrder(order.id)}>
                  <DeleteIcon fontSize="small" />
                </IconButton></Tooltip>
                {order.custPhone && <Tooltip title="WhatsApp">
                  <IconButton
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    component={props => <a href={`https://wa.me/${restaurant.callingCode.replace('+', '')}${order.custPhone}?text=${encodeURI(this.genMessage(order))}`} target="_blank" rel="noopener noreferrer" {...props} />}
                  >
                    <MessageApp fontSize="small" />
                  </IconButton>
                </Tooltip>}
              </div>
              <Spacer />
              <Divider />
              <Spacer />
              <Typography variant="h6">Summary</Typography>
              <Spacer />
              <Paper className={classes.card}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Order #
                        </TableCell>
                      <TableCell>
                        {order.id}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Created At
                        </TableCell>
                      <TableCell>
                        {formatDateTime(order.createdAt)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Order Type
                        </TableCell>
                      <TableCell>
                        {order.type.replace('_', ' ')}
                      </TableCell>
                    </TableRow>
                    {order.type === 'DINE_IN' &&
                      <TableRow>
                        <TableCell>Table Number</TableCell>
                        <TableCell>{order.tableNumber}</TableCell>
                      </TableRow>}
                    <TableRow>
                      <TableCell>
                        Status
                        </TableCell>
                      <TableCell>
                        <OrderStatusChip status={order.status} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
              {order.type !== 'DINE_IN' &&
                <div>
                  <Typography variant="h6">Customer</Typography>
                  <Spacer />
                  <Paper className={classes.card}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            Name
                        </TableCell>
                          <TableCell>
                            {order.custName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Phone
                        </TableCell>
                          <TableCell>
                            <a href={`tel:${restaurant.callingCode}${order.custPhone}`}>{restaurant.callingCode}{order.custPhone}</a>
                          </TableCell>
                        </TableRow>
                        {order.custEmail && <TableRow>
                          <TableCell>
                            Email
                          </TableCell>
                          <TableCell>
                            <a href={`mailto:${order.custEmail}`}>{order.custEmail}</a>
                          </TableCell>
                        </TableRow>}
                        {order.custAddress &&
                          <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>{order.custAddress}</TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </Paper>
                </div>}
              <Typography variant="h6">Items</Typography>
              <Spacer />
              <Paper className={classes.card + ' ' + classes.tableResponsiveContainer}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Rate</TableCell>
                      <TableCell align="right">Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.items.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell data-title="Item">
                          {item.title}
                        </TableCell>
                        <TableCell data-title="Quantity" align="right">
                          {item.quantity}
                        </TableCell>
                        <TableCell data-title="Rate" align="right">
                          {item.price}
                        </TableCell>
                        <TableCell data-title="Price" align="right">{item.quantity * item.price} {restaurant.currency}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow className={classes.totalRow}>
                      <Hidden smDown>
                        <TableCell colSpan={3}>
                          Total
                        </TableCell>
                      </Hidden>
                      <TableCell data-title="Total" align="right">
                        {order.items.map(i => i.price * i.quantity).reduce((pv, cv) => pv + cv, 0)}  {restaurant.currency}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
              <Dialog
                open={this.state.showDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{`Do you really want to delete order #${this.state.orderToDelete.id}?`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    This will delete the entire order including all of its items.
              </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div className={classes.deleteActions}>
                    <Button onClick={this.cancelDelete} variant="contained">
                      No
                </Button>
                    <Button onClick={this.confirmDelete} variant="contained" color="secondary">
                      Yes
                </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(ViewOrder);