import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles, TextField, Typography, Button, CircularProgress, FormControl, InputLabel, Select,
  MenuItem, FormHelperText, FormLabel, FormGroup, FormControlLabel, Checkbox, Card, InputAdornment
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { map, dissoc } from 'ramda';
import isValidDate from 'date-fns/isValid';

import { apolloClient } from '../apolloClient';
import { checkRestGql } from './Dashboard';
import { getRestaurantGql } from './ViewRestaurant';
import { validate } from '../validate';
import { parseGqlError } from '../utils';
import { ErrorMessage } from '../components';
import countries from '../countries';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    maxWidth: '600px',
    padding: theme.spacing.unit * 3
  },
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const setRestaurantGql = gql`
  mutation setRestaurant($restaurant: RestaurantInput!) {
    setRestaurant(restaurant: $restaurant) {
      id, name, tagline, description, address, city, country, phone, callingCode, currency, website,
      opening, closing, dineIn, takeAway, homeDelivery
    }
  }
`;

class EditRestaurant extends React.Component {
  constructor(props) {
    super(props);
    const genTime = (h, m) => {
      const d = new Date();
      d.setDate(1);
      d.setMonth(0);
      d.setFullYear(2018);
      d.setHours(h);
      d.setMinutes(m);
      d.setSeconds(0);
      d.setMilliseconds(0);
      return d;
    };
    this.state = {
      loading: true,
      error: null,
      fields: {
        name: '',
        tagline: '',
        description: '',
        city: '',
        address: '',
        country: '',
        phone: '',
        callingCode: '',
        website: '',
        opening: genTime(9, 0),
        closing: genTime(23, 0),
        dineIn: false,
        takeAway: false,
        homeDelivery: false
      },
      submitted: false,
    };
  }
  setFields = restaurant => {
    const newFields = {};
    const oldFields = this.state.fields;
    Object.keys(oldFields).forEach(field => {
      if (restaurant[field]) {
        if (['opening', 'closing'].includes(field)) {
          if (isValidDate(restaurant[field])) newFields[field] = new Date(restaurant[field]);
        } else {
          newFields[field] = restaurant[field];
        }
      }
    });
    this.setState({ fields: { ...oldFields, ...newFields } });
  }
  async componentDidMount() {
    try {
      const { data: { getRestaurant: restaurant } } = await apolloClient.query({ query: getRestaurantGql });
      this.setState({ loading: false });
      if (restaurant) this.setFields(restaurant);
    } catch (error) {
      this.setState({ loading: false, error });
    }
  }
  handleChange = name => event => {
    const { fields } = this.state;
    const newFields = {
      ...fields,
      [name]: event.target.value,
    };
    if (name === 'country') {
      const country = countries.find(c => c.name === event.target.value);
      if (country) newFields.callingCode = country.phone;
    }
    this.setState({ fields: newFields });
  };
  handleDateChange = name => value => {
    this.setState({ fields: { ...this.state.fields, [name]: value } });
  }
  handleCheckboxChange = name => event => {
    this.setState({ fields: { ...this.state.fields, [name]: event.target.checked } });
  }
  setRestaurant = setRestaurantMutation => async (event) => {
    const { history } = this.props;
    event.preventDefault();
    this.setState({ submitted: true });
    const errorField = Object.keys(this.state.fields).find(f => this.validation[f] && validate(this.validation[f], this.state.fields[f]));
    if (errorField) {
      return document.getElementById(errorField).focus();
    }
    try {
      const restaurant = map(val => val === '' ? null : val, dissoc('callingCode',this.state.fields));
      await setRestaurantMutation({ variables: { restaurant } });
      this.setState({ submitted: false });
      history.push('/restaurant/view');
    } catch (err) {
      this.setState({ submitted: false });
    }
  }
  validation = {
    name: ['required', 'isAlphaSpace', ['isByteLength', { min: 3, max: 50 }]],
    tagline: ['isAlphaSpace'],
    description: [['isByteLength', { min: 25 }]],
    address: ['required'],
    city: ['required', 'isAlphaSpace', ['isByteLength', { min: 3, max: 50 }]],
    country: ['required'],
    phone: ['required', 'isMobilePhone'],
    website: ['isURL'],
    opening: ['required'],
    closing: ['required']
  }
  render() {
    const { classes } = this.props;
    const { loading, error, fields, submitted } = this.state;
    if (loading) return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
    if (error) return <ErrorMessage error={parseGqlError(error)} />;
    const errors = {};
    Object.keys(fields).forEach(fieldName => {
      errors[fieldName] = submitted && this.validation[fieldName] && validate(this.validation[fieldName], fields[fieldName]);
    });
    return (
      <Mutation mutation={setRestaurantGql} refetchQueries={[{ query: getRestaurantGql, checkRestGql }]} awaitRefetchQueries={true}>
        {(setRestaurantMutation, { loading, error }) => (
          <Card className={classes.card}>
            <form noValidate onSubmit={this.setRestaurant(setRestaurantMutation)}>
              <fieldset disabled={loading} style={{ border: 'none' }}>
                <Typography variant="h6">
                  Setup your Restaurant
                </Typography>
                <TextField
                  required
                  fullWidth
                  type="input"
                  margin="normal"
                  autoFocus
                  id="name"
                  label="Name"
                  className={classes.textField}
                  value={fields.name}
                  onChange={this.handleChange('name')}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  fullWidth
                  type="input"
                  margin="normal"
                  id="tagline"
                  label="Tag line"
                  className={classes.textField}
                  value={fields.tagline}
                  onChange={this.handleChange('tagline')}
                  error={!!errors.tagline}
                  helperText={errors.tagline}
                />
                <TextField
                  fullWidth
                  multiline
                  rows="4"
                  type="input"
                  margin="normal"
                  id="description"
                  label="Description"
                  className={classes.textField}
                  value={fields.description}
                  onChange={this.handleChange('description')}
                  error={!!errors.description}
                  helperText={errors.description}
                />
                <TextField
                  required
                  fullWidth
                  type="input"
                  margin="normal"
                  id="address"
                  label="Address"
                  className={classes.textField}
                  value={fields.address}
                  onChange={this.handleChange('address')}
                  error={!!errors.address}
                  helperText={errors.address}
                />
                <TextField
                  required
                  fullWidth
                  type="input"
                  margin="normal"
                  id="city"
                  label="City"
                  className={classes.textField}
                  value={fields.city}
                  onChange={this.handleChange('city')}
                  error={!!errors.city}
                  helperText={errors.city}
                />
                <FormControl className={classes.textField} required fullWidth margin="normal" error={!!errors.country}>
                  <InputLabel>Country</InputLabel>
                  <Select
                    value={fields.country}
                    onChange={this.handleChange('country')}
                    inputProps={{
                      id: 'country',
                    }}
                  >
                    {countries.map(country => <MenuItem key={country.code} value={country.name}>{country.name}</MenuItem>)}
                  </Select>
                  {errors.country && <FormHelperText>{errors.country}</FormHelperText>}
                </FormControl>
                <TextField
                  required
                  fullWidth
                  type="input"
                  margin="normal"
                  id="phone"
                  label="Phone"
                  className={classes.textField}
                  value={fields.phone}
                  onChange={this.handleChange('phone')}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{fields.callingCode}</InputAdornment>,
                  }}
                />
                <TextField
                  fullWidth
                  type="input"
                  margin="normal"
                  id="website"
                  label="Website"
                  className={classes.textField}
                  value={fields.website}
                  onChange={this.handleChange('website')}
                  error={!!errors.website}
                  helperText={errors.website}
                  placeholder="https://domain.com"
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    fullWidth
                    required
                    margin="normal"
                    label="Opening"
                    value={fields.opening}
                    onChange={this.handleDateChange('opening')}
                    error={!!errors.opening}
                    helperText={errors.opening}
                  />
                  <TimePicker
                    fullWidth
                    required
                    margin="normal"
                    label="Closing"
                    value={fields.closing}
                    onChange={this.handleDateChange('closing')}
                    error={!!errors.closing}
                    helperText={errors.closing}
                  />
                </MuiPickersUtilsProvider>
                <FormControl fullWidth margin="normal">
                  <FormLabel>Services</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fields.dineIn}
                          onChange={this.handleCheckboxChange('dineIn')}
                          value="dineIn"
                        />
                      }
                      label="Dine In"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fields.takeAway}
                          onChange={this.handleCheckboxChange('takeAway')}
                          value="takeAway"
                        />
                      }
                      label="Take Away"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={fields.homeDelivery}
                          onChange={this.handleCheckboxChange('homeDelivery')}
                          value="homeDelivery"
                        />
                      }
                      label="Home Delivery"
                    />
                  </FormGroup>
                </FormControl>
                {error && <ErrorMessage error={parseGqlError(error)} />}
                <div className={classes.actionBar}>
                  <Button variant="contained" component={Link} to="/restaurant/view">
                    Cancel
                </Button>
                  {loading ? <CircularProgress size={30} /> :
                    <Button type="submit" variant="contained" color="primary">
                      Save
                  </Button>
                  }
                </div>
              </fieldset>
            </form>
          </Card>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(EditRestaurant);