import React from 'react';
import {
  withStyles, TextField, Typography, Button, CircularProgress
} from '@material-ui/core';
import gql from 'graphql-tag';
import { apolloClient } from '../apolloClient';
import { parseGqlError } from '../utils';
import { validate } from '../validate';
import { appEvents } from '../App';
import { ErrorMessage } from '../components';

const styles = theme => ({
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const getTokenGql = gql`
  query getToken($email: EmailAddress!, $password: String!) {
    getToken(email: $email, password: $password) {
      userId
      token
    }
  }
`;

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: '',
        password: '',
      },
      loading: false,
      error: null,
      submitted: false
    };
  }
  validation = {
    email: ['required', 'isEmail'],
    password: ['required']
  }
  handleChange = name => event => {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [name]: event.target.value,
      }
    });
  };
  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const errorField = Object.keys(this.state.fields)
      .find(field => validate(this.validation[field], this.state.fields[field]));
    if (errorField) {
      return document.getElementById(errorField).focus();
    }
    const { email, password } = this.state.fields;
    try {
      this.setState({ error: null, loading: true });
      const { data: { getToken: { userId, token } } } = await apolloClient.query({ query: getTokenGql, variables: { email, password }, fetchPolicy: 'no-cache' });
      appEvents.emit('signIn', userId, token, () => {
        const { history, location } = this.props;
        if (location.state && location.state.from) {
          history.push(location.state.from);
        }
      });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }
  render() {
    const { classes, location } = this.props;
    const { fields, submitted, loading, error } = this.state;
    const errors = {};
    Object.keys(fields).forEach(fieldName => {
      errors[fieldName] = submitted && validate(this.validation[fieldName], fields[fieldName]);
    });
    return (
      <form noValidate onSubmit={(e) => this.handleSubmit(e)}>
        <Typography variant="subtitle1">
          {(location.state && location.state.msg) || 'Enter credentials to login'}
        </Typography>
        <TextField
          required
          fullWidth
          autoFocus
          type="input"
          margin="normal"
          id="email"
          label="Email"
          className={classes.textField}
          value={fields.email}
          onChange={this.handleChange('email')}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          required
          fullWidth
          type="password"
          margin="normal"
          id="password"
          label="Password"
          className={classes.textField}
          value={fields.password}
          onChange={this.handleChange('password')}
          error={!!errors.password}
          helperText={errors.password}
        />
        {error && <ErrorMessage error={parseGqlError(error)} />}
        <div className={classes.actionBar}>
          <span></span>
          {loading ? <CircularProgress size={30} /> :
            <Button type="submit" className={classes.signIn} variant="contained" color="primary">
              Next
              </Button>
          }
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(Signin);