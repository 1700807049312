import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import {
  withStyles, AppBar, Toolbar, Typography, Button, IconButton, ListItem, ListItemText, Drawer, List, ListItemIcon, Hidden, Divider, CircularProgress, Snackbar
} from '@material-ui/core';
import { DateRange as DateRangeIcon, AttachMoney as AttachMoneyIcon, Menu as MenuIcon, ExitToApp as ExitToAppIcon, AccountCircle as AccountCircleIcon, Restaurant as RestaurantIcon, RestaurantMenu as RestaurantMenuIcon, Person as PersonIcon, ShoppingBasket as ShoppingBasketIcon, Close as CloseIcon } from '@material-ui/icons';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import logo from '../logo.png';
import { appEvents } from '../App';
import { wsLink, apolloClient } from '../apolloClient';
import { getOrdersGql } from '../pages/ListOrders';
import { isTrialValid } from '../pages/ViewBilling';
import { ErrorMessage } from '../components';
import { parseGqlError } from '../utils';

const drawerWidth = 240;

const styles = theme => ({
  flex: {
    flex: 1,
  },
  root: {
    flexGrow: 1,
    zIndex: 1,
    display: 'flex'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    '@media print': {
      display: 'none'
    }
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    position: 'fixed !important',
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
    height: '100vh',
    '@media print': {
      display: 'none'
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    marginLeft: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  leftSmallIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
});

export const getUserGql = gql`
  {
    getUser {
      id, email, firstName, lastName, role, trialExpiration, subscription {
        id
      }
    }
  }
`;

const orderPlacedGql = gql`
  subscription {
    orderPlaced {
      id, type, status, tableNumber, custName, custEmail, custPhone, custAddress, createdAt, items {
        id, title, price, quantity
      }
    }
  }
`;

class User extends React.Component {

  state = {
    mobileOpen: false
  }

  pageTitles = [
    [/^\/$/, 'Dashboard'],
    [/^\/restaurant/, 'Manage Restaurant'],
    [/^\/menu/, 'Manage Menu'],
    [/^\/orders/, 'Manage Orders'],
    [/^\/reservations/, 'Manage Reservations'],
    [/^\/account/, 'Manage Account'],
    [/^\/billing/, 'Billing']
  ];

  componentDidMount() {
    wsLink.subscriptionClient.connect();
    const orderPlaced = apolloClient.subscribe({ query: orderPlacedGql });
    const instance = this;
    orderPlaced.subscribe({
      next({ data: { orderPlaced } = {} }) {
        instance.openNotitication({
          message: 'New Order Placed Online #' + orderPlaced.id,
          url: '/orders/view/' + orderPlaced.id
        });
        const { type } = orderPlaced;
        try {
          const { getOrders } = apolloClient.readQuery({ query: getOrdersGql, variables: { type } });
          getOrders.push(orderPlaced);
          apolloClient.writeQuery({ query: getOrdersGql, variables: { type }, data: { getOrders } });
        } catch (error) {

        }
      },
      error(err) { console.log(err) }
    });
  }

  componentWillUnmount() {
    wsLink.subscriptionClient.close();
  }

  openNotitication = (notification) => {
    this.setState({ notification });
  }

  closeNotification = () => {
    this.setState({ notification: null });
  }

  getTitle = () => {
    const { pathname } = this.props.location;
    for (const [pattern, title] of this.pageTitles) {
      if (pattern.test(pathname)) return title;
    }
    return 'Not Found';
  }

  isNavSelected = (i) => {
    const { pathname } = this.props.location;
    return this.pageTitles[i][0].test(pathname);
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  navigate = (route) => () => {
    const { history } = this.props;
    history.push(route);
    this.setState({ mobileOpen: false });
  };

  getDrawer = (isBillingRequired) => {
    const { classes } = this.props;
    return (
      <div>
        <div className={`${classes.toolbar} ${classes.logoContainer}`}>
          <img src={logo} height="48" alt="ServeTreats Logo" onClick={this.navigate('/')} style={{ cursor: 'pointer' }} />
        </div>
        <Divider />
        <List>
          <ListItem button selected={this.isNavSelected(1)} disabled={isBillingRequired}>
            <ListItemIcon>
              <RestaurantIcon />
            </ListItemIcon>
            <ListItemText primary="Restaurant" onClick={this.navigate('/restaurant/view')} />
          </ListItem>
          <ListItem button selected={this.isNavSelected(2)} disabled={isBillingRequired}>
            <ListItemIcon>
              <RestaurantMenuIcon />
            </ListItemIcon>
            <ListItemText primary="Menu" onClick={this.navigate('/menu/view')} />
          </ListItem>
          <ListItem button selected={this.isNavSelected(3)} disabled={isBillingRequired}>
            <ListItemIcon>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" onClick={this.navigate('/orders/list')} />
          </ListItem>
          <ListItem button selected={this.isNavSelected(4)} disabled={isBillingRequired}>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary="Reservations" onClick={this.navigate('/reservations/list')} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button selected={this.isNavSelected(5)} disabled={isBillingRequired}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Account" onClick={this.navigate('/account/view')} />
          </ListItem>
          <ListItem button selected={this.isNavSelected(6)}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Billing" onClick={this.navigate('/billing/view')} />
          </ListItem>
        </List>
      </div>
    );
  }

  render() {
    const { classes, children, history } = this.props;
    const { notification } = this.state;
    return (
      <Query query={getUserGql}>
        {
          ({ loading, error, data: { getUser: user } = {} }) => {
            if (loading) {
              return (
                <div className={classes.loadingContainer}>
                  <CircularProgress />
                </div>
              );
            }
            if (error) return <ErrorMessage error={parseGqlError(error)} />;
            const isBillingRequired = !isTrialValid(user.trialExpiration) && !user.subscription;
            const { location } = this.props;
            if (isBillingRequired && !/^\/billing/.test(location.pathname)) {
              return <Redirect to="/billing/view" />;
            }
            return (
              <div className={classes.root}>
                <AppBar color="primary" className={classes.appBar} elevation={2}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.handleDrawerToggle}
                      className={classes.navIconHide}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" color="inherit" className={classes.flex} noWrap>
                      {this.getTitle()}
                    </Typography>
                    <div className={classes.userInfo}>
                      <AccountCircleIcon />
                      <Typography color="inherit" title={user.email}>
                        &nbsp;{user.firstName}&nbsp;&nbsp;
                    </Typography>
                    </div>
                    <IconButton title="Sign out" color="inherit" onClick={() => appEvents.emit('signOut')}>
                      <ExitToAppIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <Hidden mdUp>
                  <Drawer
                    variant="temporary"
                    open={this.state.mobileOpen}
                    onClose={this.handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    {this.getDrawer(isBillingRequired)}
                  </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                  <Drawer
                    variant="permanent"
                    open
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    {this.getDrawer(isBillingRequired)}
                  </Drawer>
                </Hidden>
                <main className={classes.content}>
                  <div className={classes.toolbar} />
                  <div className={classes.pageContainer}>
                    {children}
                  </div>
                </main>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  message={notification && notification.message}
                  open={!!notification}
                  autoHideDuration={8000}
                  onClose={this.closeNotification}
                  action={[
                    <Button key="view" color="secondary" size="small" onClick={() => notification.url && history.push(notification.url)}>View</Button>,
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.closeNotification}
                    >
                      <CloseIcon />
                    </IconButton>
                  ]}
                />
              </div>
            );
          }}
      </Query>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(User));
