import datefnsFormat from 'date-fns/format';
import { map } from 'ramda';

export const formatDateTime = (date) => datefnsFormat(date, 'dd MMM yyyy, hh:mm a');

export const formatDate = (date) => datefnsFormat(date, 'dd MMM yyyy');

export const formatTime = (date) => datefnsFormat(date, 'hh:mm a');

export const randomId = () => Date.now().toString();

export const emptyStrToNull = (obj) => map(val => val === '' ? null : val, obj);

export const nullToEmptyStr = (obj) => map(val => val === null ? '' : val, obj);

export const parseGqlError = (error) => {
  console.error(error);
  return  (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message) ||
          (error.networkError && error.networkError.message) || error;
};

export const getOnlyDate = (date) => {
  const d = new Date(date);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d.toISOString();
};
