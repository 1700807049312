import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import {
  withStyles, Table, TableRow, TableCell, TableBody, Card, CardContent, Typography, IconButton, Button,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Tooltip
} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { apolloClient } from '../apolloClient';
import { parseGqlError } from '../utils';
import { Spacer, ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  card: {
    marginBottom: theme.spacing.unit * 3
  },
  table: {
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  tableCell: {
    padding: '1rem'
  },
  deleteActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  }
});

export const getMenuCategoriesGql = gql`
  {
    getMenuCategories {
      id, title, description, items {
        id, title, description, price
      }
    }
    getRestaurant {
      id, currency
    }
  }
`;

const removeMenuCategoryGql = gql`
  mutation removeMenuCategory($id: ID!) {
    removeMenuCategory(id: $id) {
      id, title, description, items {
        id, title, description, price
      }
    }
  }
`;

class ViewMenu extends React.Component {
  state = {
    categoryToDelete: '',
    showDeleteDialog: false,
    error: null
  }
  removeCategory = (id, title) => {
    this.setState({ showDeleteDialog: true, categoryToDelete: { id, title } })
  }
  confirmDelete = async () => {
    const { id } = this.state.categoryToDelete;
    this.setState({ showDeleteDialog: false, categoryToDelete: '' });
    try {
      await apolloClient.mutate({
        mutation: removeMenuCategoryGql,
        variables: { id },
        update: (proxy) => {
          const { getMenuCategories } = proxy.readQuery({ query: getMenuCategoriesGql });
          getMenuCategories.splice(getMenuCategories.findIndex(r => r.id === id), 1);
          proxy.writeQuery({ query: getMenuCategoriesGql, data: { getMenuCategories } });
          this.setState({ categoryToDelete: '' });
        }
      });
    } catch (err) {
      this.setState({ error: parseGqlError(err) });
    }
  }
  cancelDelete = () => {
    this.setState({ showDeleteDialog: false, categoryToDelete: '' })
  }
  render() {
    const { classes, history } = this.props;
    return (
      <Query query={getMenuCategoriesGql}>
        {({ loading, error, data: { getMenuCategories: categories, getRestaurant: restaurant } = {} }) => {
          if (loading) return (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          );
          if (error) return <ErrorMessage error={parseGqlError(error)} />;
          if (categories.length === 0) return <Redirect to="/menu/add" />
          return (
            <div>
              {categories.map((category) => (
                <Card key={category.id} className={classes.card}>
                  <CardContent>
                    <div className={classes.itemHeader}>
                      <Typography variant="h6">{category.title}</Typography>
                      <div>
                        <Tooltip title="Edit Category">
                          <IconButton onClick={() => history.push(`/menu/edit/${category.id}`)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Category">
                          <IconButton onClick={() => this.removeCategory(category.id, category.title)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <Typography variant="subtitle1" color="textSecondary">{category.description}</Typography>
                    <Spacer />
                    <Table className={classes.table}>
                      <TableBody>
                        {category.items.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell className={classes.tableCell}>
                              <Typography variant="subtitle2">{item.title}</Typography>
                              <Typography color="textSecondary">{item.description}</Typography>
                            </TableCell>
                            <TableCell align="right" className={classes.tableCell}>{item.price} {restaurant.currency}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              ))}
              <Tooltip title="Add Category">
                <Fab size="small" color="primary" component={Link} to="/menu/add">
                  <AddIcon />
                </Fab>
              </Tooltip>
              <Dialog
                open={this.state.showDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{`Do you really want to delete ${this.state.categoryToDelete.title}?`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    This will delete the entire category including all of its items.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div className={classes.deleteActions}>
                    <Button onClick={this.cancelDelete} variant="contained">
                      No
                    </Button>
                    <Button onClick={this.confirmDelete} variant="contained" color="secondary">
                      Yes
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(ViewMenu);