import React from 'react';
import {
  withStyles, Table, TableRow, TableCell, TableBody, Typography, IconButton, Fab, CircularProgress, Tabs, Tab, AppBar, TableHead, Paper, Chip, Tooltip
} from '@material-ui/core';
import {
  Add as AddIcon, Restaurant as RestaurantIcon, Motorcycle as MotorcycleIcon, TransferWithinAStation as TransferWithinAStationIcon, NavigateNext as NavigateNextIcon
} from '@material-ui/icons';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { sortBy } from 'ramda';

import { getRestaurantInfoGql } from './ViewRestaurant';
import { parseGqlError, formatDateTime } from '../utils';
import { tableResponsiveContainer, greenChip } from '../globalStyles';
import { Spacer, StatsCountChip, ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  tableResponsiveContainer: tableResponsiveContainer(theme),
  greenChip: greenChip(theme)
});

export const getOrdersGql = gql`
  query getOrders($type: OrderType!) {
    getOrders(type: $type) {
      id, type, status, tableNumber, custName, custEmail, custPhone, custAddress, createdAt, items {
        id, title, price, quantity
      }
    }
  }
`;

export const OrderStatusChip = withStyles(styles)(({ status, classes }) => (<Chip
  label={status}
  color={['SERVED', 'DISPATCHED', 'READY', 'COMPLETE'].includes(status) ? 'primary' : (status === 'CANCELED' ? 'secondary' : 'default')}
  className={status === 'COMPLETE' ? classes.greenChip : ''}
/>));

export const getOrdersListType = () => {
  const ordersListType = sessionStorage.getItem('ordersListType');
  const valid = ['DINE_IN', 'HOME_DELIVERY', 'TAKE_AWAY'].includes(ordersListType);
  return valid ? ordersListType : 'DINE_IN';
}

class ListOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      orderType: getOrdersListType()
    };
  }
  onTabChange = async (e, orderType) => {
    this.setState({ orderType });
    sessionStorage.setItem('ordersListType', orderType);
  }
  getStats = (orders) => {
    const counts = {
      'Pending': orders.filter(r => r.status === 'PENDING').length,
      'Served': orders.filter(r => r.status === 'SERVED').length,
      'Dispatched': orders.filter(r => r.status === 'DISPATCHED').length,
      'Ready': orders.filter(r => r.status === 'READY').length,
      'Complete': orders.filter(r => r.status === 'COMPLETE').length,
      'Canceled': orders.filter(r => r.status === 'CANCELED').length
    };
    return Object.keys(counts).filter(k => counts[k] > 0).map((k) => (
      <StatsCountChip key={k} label={`${counts[k]} ${k}`} />
    ));
  }
  render() {
    const { classes, history } = this.props;
    const { orderType } = this.state;
    return (
      <>
        <AppBar position="static" color="default">
          <Tabs value={orderType} variant="fullWidth" onChange={this.onTabChange}>
            <Tab label="Dine In" value="DINE_IN" icon={<RestaurantIcon />} />
            <Tab label="Home Delivery" value="HOME_DELIVERY" icon={<MotorcycleIcon />} />
            <Tab label="Take Away" value="TAKE_AWAY" icon={<TransferWithinAStationIcon />} />
          </Tabs>
        </AppBar>
        <Spacer />
        <Tooltip title="Add New Order">
          <Fab size="small" color="primary" onClick={() => history.push('/orders/add')}>
            <AddIcon />
          </Fab>
        </Tooltip>
        <Spacer />
        <Query query={getRestaurantInfoGql}>
          {({ loading, error, data: { getRestaurant: restaurant } = {} }) => {
            if (loading) return (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            );
            if (error) return <ErrorMessage error={parseGqlError(error)} />;
            return (
              <Query query={getOrdersGql} variables={{ type: orderType }}>
                {({ loading, error, data: { getOrders: orders } = {} }) => {
                  if (loading) return (
                    <div className={classes.loadingContainer}>
                      <CircularProgress />
                    </div>
                  );
                  if (error) return <ErrorMessage error={parseGqlError(error)} />;
                  if (orders.length === 0) return (
                    <Typography variant="subtitle1" align="center">
                      No orders. Click on add button to create.
                </Typography>
                  );
                  return (
                    <>
                      <Paper className={classes.tableResponsiveContainer}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Time</TableCell>
                              {orderType === 'DINE_IN' && <TableCell>Table</TableCell>}
                              {(orderType === 'HOME_DELIVERY' || orderType === 'TAKE_AWAY') && <>
                                <TableCell>Name</TableCell>
                                <TableCell>Phone</TableCell>
                              </>}
                              <TableCell>Status</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortBy(o => - new Date(o.createdAt), orders).map((order) => (
                              <TableRow key={order.id}>
                                <TableCell data-title="#">
                                  {order.id}
                                </TableCell>
                                <TableCell data-title="Time">
                                  {formatDateTime(order.createdAt)}
                                </TableCell>
                                {orderType === 'DINE_IN' && <TableCell data-title="Table">{order.tableNumber}</TableCell>}
                                {(orderType === 'HOME_DELIVERY' || orderType === 'TAKE_AWAY') && <>
                                  <TableCell data-title="Name">{order.custName}</TableCell>
                                  <TableCell data-title="Phone"><a href={`tel:${restaurant.callingCode}${order.custPhone}`}>{restaurant.callingCode}{order.custPhone}</a></TableCell>
                                </>}
                                <TableCell data-title="Status">
                                  <OrderStatusChip status={order.status} />
                                </TableCell>
                                <TableCell className="actions">
                                  <Tooltip title="Order Details"><IconButton onClick={() => history.push('/orders/view/' + order.id)}>
                                    <NavigateNextIcon fontSize="small" />
                                  </IconButton></Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                      <Spacer />
                      <Typography variant="subtitle1" align="center">
                        {this.getStats(orders)}
                      </Typography>
                    </>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </>
    );
  }
}

export default withStyles(styles)(ListOrders);