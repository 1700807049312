import React from 'react';
import {
  withStyles, Card, CardContent, Typography, IconButton, CircularProgress, Chip,
  Paper, Table, TableHead, TableBody, TableCell, TableRow, Fab, Tooltip
} from '@material-ui/core';
import {
  Add as AddIcon, KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon, NavigateNext as NavigateNextIcon
} from '@material-ui/icons';
import { MuiPickersUtilsProvider, Calendar } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { sortBy } from 'ramda';
import { isValid as isValidDate } from 'date-fns';

import { getRestaurantInfoGql } from './ViewRestaurant';
import { parseGqlError, formatTime, getOnlyDate } from '../utils';
import { tableResponsiveContainer, greenChip } from '../globalStyles';
import { Spacer, StatsCountChip, ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    marginBottom: theme.spacing.unit * 3
  },
  tableResponsiveContainer: tableResponsiveContainer(theme),
  deleteActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  },
  totalRow: {
    backgroundColor: theme.palette.background.default
  },
  resDay: {
    position: 'relative',
    width: '10px',
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    left: '15px',
    top: '-10px'
  },
  greenChip: greenChip(theme)
});

export const getReservationsDatesGql = gql`
  {
    getReservationsDates
  }
`;

export const getReservationsGql = gql`
  query getReservations($date: DateTime!) {
    getReservations(date: $date) {
      id, datetime, persons, name, email, phone, notes, status
    }
  }
`;

export const getReservationsListDate = () => {
  const reservationsDate = sessionStorage.getItem('reservationsListDate');
  const valid = isValidDate(reservationsDate);
  return valid ? new Date(reservationsDate) : new Date();
}


export const ReservationStatusChip = withStyles(styles)(({ classes, status, datetime }) => {
  if (!status && new Date(datetime) >= new Date()) {
    return <Chip
      label="Up Coming"
      color="primary"
    />
  }
  if (!status && new Date(datetime) < new Date()) {
    return <Chip
      label="No Show"
      color="default"
    />
  }
  if (status === 'ARRIVED') {
    return <Chip
      label="Arrived"
      color="primary"
      className={classes.greenChip}
    />
  }
  if (status === 'CANCELED') {
    return <Chip
      label="Canceled"
      color="secondary"
    />;
  }
  return null;
});

class ListReservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: getReservationsListDate(),
      error: null
    };
  }
  handleDateChange = value => {
    this.setState({ selectedDate: value });
    sessionStorage.setItem('reservationsListDate', value.toISOString());
  }
  isDateHasReservations = (date, reservationDates) => {
    return !!reservationDates.map(d => new Date(d)).find(d => d.toDateString() === date.toDateString());
  }
  renderReservationDate = reservationDates => (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    const { classes: { resDay } } = this.props;
    return (
      <span>
        {dayComponent}
        <div className={dayInCurrentMonth && this.isDateHasReservations(date, reservationDates) ? resDay : ''} />
      </span>
    );
  };
  getStats = (reservations) => {
    const counts = {
      'Up Coming': reservations.filter(r => !r.status && new Date(r.datetime) >= new Date()).length,
      'No Show': reservations.filter(r => !r.status && new Date(r.datetime) < new Date()).length,
      'Arrived': reservations.filter(r => r.status === 'ARRIVED').length,
      'Canceled': reservations.filter(r => r.status === 'CANCELED').length
    };
    return Object.keys(counts).filter(k => counts[k] > 0).map((k) => (
      <StatsCountChip key={k} label={`${counts[k]} ${k}`} />
    ));
  }
  render() {
    const { classes, history } = this.props;
    const { selectedDate } = this.state;
    return (
      <Query query={getRestaurantInfoGql}>
        {({ loading, error, data: { getRestaurant: restaurant } = {} }) => {
          if (loading) return (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          );
          if (error) return <ErrorMessage error={parseGqlError(error)} />;
          return (
            <Query query={getReservationsDatesGql}>
              {({ loading, error, data: { getReservationsDates: reservationDates } = {} }) => {
                if (loading) return (
                  <div className={classes.loadingContainer}>
                    <CircularProgress />
                  </div>
                );
                if (error) return <ErrorMessage error={parseGqlError(error)} />;
                return (
                  <>
                    <Card>
                      <CardContent>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Calendar
                            leftArrowIcon={<KeyboardArrowLeftIcon />}
                            rightArrowIcon={<KeyboardArrowRightIcon />}
                            date={selectedDate}
                            onChange={this.handleDateChange}
                            renderDay={this.renderReservationDate(reservationDates)}
                          />
                        </MuiPickersUtilsProvider>
                      </CardContent>
                    </Card>
                    <Spacer />
                    <Tooltip title="Add New Reservation">
                      <Fab size="small" color="primary" onClick={() => history.push('/reservations/add')}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                    <Spacer />
                    <Query query={getReservationsGql} variables={{ date: getOnlyDate(selectedDate) }}>
                      {({ loading, error, data: { getReservations: reservations } = {} }) => {
                        if (loading) return (
                          <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                          </div>
                        );
                        if (error) return <ErrorMessage error={parseGqlError(error)} />;
                        if (reservations.length === 0) return (
                          <Typography variant="subtitle1" align="center">
                            No reservations for this date. Click on add button to create.
                    </Typography>
                        );
                        return (
                          <>
                            <Paper className={classes.tableResponsiveContainer}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Persons</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {sortBy(r => new Date(r.datetime), reservations).map(reservation => (
                                    <TableRow key={reservation.id}>
                                      <TableCell data-title="#">
                                        {reservation.id}
                                      </TableCell>
                                      <TableCell data-title="Time">
                                        {formatTime(reservation.datetime)}
                                      </TableCell>
                                      <TableCell data-title="Persons">{reservation.persons}</TableCell>
                                      <TableCell data-title="Name">{reservation.name}</TableCell>
                                      <TableCell data-title="Phone">
                                        <a href={`tel:${restaurant.callingCode}${reservation.phone}`}>{restaurant.callingCode}{reservation.phone}</a>
                                      </TableCell>
                                      <TableCell data-title="Status">
                                        <ReservationStatusChip status={reservation.status} datetime={reservation.datetime} />
                                      </TableCell>
                                      <TableCell className="actions">
                                        <Tooltip title="Order Details"><IconButton onClick={() => history.push('/reservations/view/' + reservation.id)}>
                                          <NavigateNextIcon fontSize="small" />
                                        </IconButton></Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Paper>
                            <Spacer />
                            <Typography variant="subtitle1" align="center">
                              {this.getStats(reservations)}
                            </Typography>
                          </>
                        );
                      }}
                    </Query>
                  </>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(ListReservations);