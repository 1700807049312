import React from 'react';
import {
  withStyles, TextField, Typography, Button, CircularProgress, Card, CardContent, IconButton,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, Fab, Tooltip, InputAdornment
} from '@material-ui/core';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import gql from 'graphql-tag';
import { dissoc, clone } from 'ramda';

import { apolloClient } from '../apolloClient';
import { getMenuCategoriesGql } from './ViewMenu';
import { getOrdersGql, getOrdersListType } from './ListOrders';
import { getStatsGql } from './Dashboard';
import { getRestaurantInfoGql } from './ViewRestaurant';
import CustomSelect from '../components/CustomSelect';
import { validate } from '../validate';
import { parseGqlError, emptyStrToNull, nullToEmptyStr } from '../utils';
import { Spacer, ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    marginTop: theme.spacing.unit * 3
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const getOrderGql = gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id, type, status, tableNumber, custName, custEmail, custPhone, custAddress, createdAt, items {
        id, title, price, quantity
      }
    }
  }
`;

const addOrderGql = gql`
  mutation addOrder($order: OrderInput!) {
    addOrder(order: $order) {
      id, type, status, tableNumber, custName, custEmail, custPhone, custAddress, createdAt, items {
        id, title, price, quantity
      }
    }
  }
`;

const updateOrderGql = gql`
  mutation updateOrder($id: ID!, $order: OrderInput!) {
    updateOrder(id: $id, order: $order) {
      id, type, status, tableNumber, custName, custEmail, custPhone, custAddress, createdAt, items {
        id, title, price, quantity
      }
    }
  }
`;

class EditOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOptions: [],
      loading: true,
      loadingError: null,
      updating: false,
      updatingError: null,
      newItem: {
        item: null,
        quantity: '1'
      },
      order: {
        type: getOrdersListType(),
        tableNumber: '',
        custName: '',
        custEmail: '',
        custPhone: '',
        custAddress: '',
        items: []
      },
      submitted: false,
    };
  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    try {
      this.setState({ loading: true });
      const { data: { getRestaurant: restaurant } = {} } = await apolloClient.query({
        query: getRestaurantInfoGql
      });
      this.setState({ restaurant });
      const { data: { getMenuCategories: categories } = {} } = await apolloClient.query({
        query: getMenuCategoriesGql
      });
      if (categories.length > 0) {
        const menuOptions = categories.map(c => ({
          label: c.title,
          options: c.items.map(i => ({
            label: `${i.title} - ${i.price} ${restaurant.currency}`,
            value: i.title,
            id: i.id,
            title: i.title,
            price: i.price,
          }))
        }));
        this.setState({ menuOptions });
      }
      if (id) {
        const { data: { getOrder: order } = {} } = await apolloClient.query({
          query: getOrderGql, variables: { id }
        });
        this.setState({ order: nullToEmptyStr(clone(order)), oldType: order.type });
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, loadingError: error });
    }
  }
  handleNewItemChange = prop => event => {
    const { newItem } = this.state;
    this.setState({
      newItem: {
        ...newItem,
        [prop]: event.target ? event.target.value : event
      }
    });
  };
  handleOrderChange = prop => event => {
    const { order } = this.state;
    this.setState({
      order: {
        ...order,
        [prop]: event.target.value
      }
    });
  };
  upsertOrder = async (event) => {
    const { history, match } = this.props;
    const { order } = this.state;
    event.preventDefault();
    this.setState({ submitted: true });
    const fieldNames = Object.keys(this.validation).filter((f) => {
      if (order.type === 'DINE_IN') return f === 'tableNumber';
      else if (order.type === 'TAKE_AWAY') return ['custName', 'custPhone'].includes(f);
      else if (order.type === 'HOME_DELIVERY') return ['custName', 'custPhone', 'custAddress'].includes(f);
      return false;
    });
    const errorField = fieldNames.find(f => validate(this.validation[f], order[f]));
    if (errorField) {
      return document.getElementById(errorField).focus();
    }
    if (order.items.length === 0) {
      return document.getElementById('newItem').focus();
    }
    try {
      this.setState({ updating: true });
      const { id, type, tableNumber, custName, custEmail, custPhone, custAddress, items } = order;
      const orderToSend = emptyStrToNull({ type, tableNumber, custName, custEmail, custPhone, custAddress, items });
      const { data: { addOrder, updateOrder } } = await apolloClient.mutate({
        mutation: match.params.id ? updateOrderGql : addOrderGql,
        variables: {
          id,
          order: {
            ...orderToSend,
            items: orderToSend.items.map(
              ({ title, price, quantity }) => (dissoc('id', {
                title, price: Number(price), quantity: Number(quantity)
              }))
            )
          }
        },
        refetchQueries: match.params.id ? [] : [{ query: getStatsGql }],
        update: (proxy, { data: { addOrder, updateOrder } }) => {
          if (addOrder) {
            const { getOrders } = proxy.readQuery({ query: getOrdersGql, variables: { type } });
            getOrders.push(addOrder);
            proxy.writeQuery({ query: getOrdersGql, variables: { type }, data: { getOrders } });
          } else if (updateOrder && updateOrder.type !== this.state.oldType) {
            const { getOrders } = proxy.readQuery({ query: getOrdersGql, variables: { type: this.state.oldType } });
            getOrders.splice(getOrders.findIndex(o => o.id === updateOrder.id ), 1);
            proxy.writeQuery({ query: getOrdersGql, variables: { type: this.state.oldType }, data: { getOrders } });
            const { getOrders: newOrders } = proxy.readQuery({ query: getOrdersGql, variables: { type: updateOrder.type } });
            if (newOrders) {
              newOrders.push(updateOrder);
              proxy.writeQuery({ query: getOrdersGql, variables: { type: updateOrder.type }, data: { getOrders: newOrders } });
            }
          }
        }
      });
      this.setState({ submitted: false, updating: false });
      sessionStorage.setItem('ordersListType', type);
      history.push('/orders/view/' + (addOrder || updateOrder).id);
    } catch (err) {
      this.setState({ submitted: false, updating: false, updatingError: err });
    }
  }
  validation = {
    tableNumber: ['required'],
    custName: ['required'],
    custEmail: ['isEmail'],
    custPhone: ['required', 'isMobilePhone'],
    custAddress: ['required']
  }
  addItem = () => {
    const { order, newItem: { item, quantity } } = this.state;
    if (!item || !quantity) {
      this.setState({ itemError: true });
      return;
    };
    const { id, title, price } = item;
    const existingItem = order.items.find(i => i.id === id);
    if (existingItem) {
      existingItem.quantity += Number(quantity);
    } else {
      order.items.push({ id, title, price, quantity: Number(quantity) });
    }
    this.setState({ order, newItem: { item: null, quantity: '1' }, itemError: false });
  }
  removeItem = (index) => {
    const { order } = this.state;
    order.items.splice(index, 1);
    this.setState({ order });
  }
  render() {
    const { classes, history, match } = this.props;
    const { loading, loadingError, updating, updatingError, order, newItem, itemError, submitted, restaurant } = this.state;
    if (loading) return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
    if (loadingError) return <ErrorMessage error={parseGqlError(loadingError)} />;
    const errors = {};
    Object.keys(this.validation).forEach(fieldName => {
      errors[fieldName] = submitted && validate(this.validation[fieldName], order[fieldName]);
    });
    return (
      <form noValidate style={{ maxWidth: '600px' }} onSubmit={this.upsertOrder}>
        <fieldset disabled={updating} style={{ border: 'none' }}>
          <Typography variant="h6">
            {match.params.id ? `Edit Order #${match.params.id}` : 'Add New Order'}
          </Typography>
          <Spacer />
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Type</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender2"
              className={classes.group}
              value={order.type}
              onChange={this.handleOrderChange('type')}
            >
              <FormControlLabel
                value="DINE_IN"
                control={<Radio color="primary" />}
                label="Dine In"
                labelPlacement="end"
              />
              <FormControlLabel
                value="HOME_DELIVERY"
                control={<Radio color="primary" />}
                label="Home Delivery"
                labelPlacement="end"
              />
              <FormControlLabel
                value="TAKE_AWAY"
                control={<Radio color="primary" />}
                label="Take Away"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
          {(order.type === 'DINE_IN') &&
            <TextField
              required
              fullWidth
              type="input"
              margin="normal"
              id="tableNumber"
              label="Table Number"
              className={classes.textField}
              value={order.tableNumber}
              onChange={this.handleOrderChange('tableNumber')}
              error={!!errors.tableNumber}
              helperText={errors.tableNumber}
            />
          }
          {
            (order.type === 'TAKE_AWAY' || order.type === 'HOME_DELIVERY') &&
            <React.Fragment>
              <Spacer />
              <Typography variant="h6">
                Customer Info
              </Typography>
              <TextField
                required
                fullWidth
                type="input"
                margin="none"
                id="custName"
                label="Name"
                className={classes.textField}
                value={order.custName}
                onChange={this.handleOrderChange('custName')}
                error={!!errors.custName}
                helperText={errors.custName}
              />
              <TextField
                required
                fullWidth
                type="input"
                margin="normal"
                id="custPhone"
                label="Phone"
                className={classes.textField}
                value={order.custPhone}
                onChange={this.handleOrderChange('custPhone')}
                error={!!errors.custPhone}
                helperText={errors.custPhone}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{restaurant.callingCode}</InputAdornment>,
                }}
              />
              <TextField
                fullWidth
                type="input"
                margin="normal"
                id="custEmail"
                label="Email"
                className={classes.textField}
                value={order.custEmail}
                onChange={this.handleOrderChange('custEmail')}
                error={!!errors.custEmail}
                helperText={errors.custEmail}
              />
            </React.Fragment>
          }
          {(order.type === 'HOME_DELIVERY') &&
            <TextField
              required
              fullWidth
              type="input"
              margin="normal"
              id="custAddress"
              label="Address"
              className={classes.textField}
              value={order.custAddress}
              onChange={this.handleOrderChange('custAddress')}
              error={!!errors.custAddress}
              helperText={errors.custAddress}
            />
          }
          <Spacer />
          <Typography variant="h6">
            Items
          </Typography>
          <CustomSelect
            inputId="newItem"
            required
            options={this.state.menuOptions}
            value={newItem.item}
            onChange={this.handleNewItemChange('item')}
            placeholder="Select Item..."
          />
          {itemError && !newItem.item && <FormHelperText error={true}>Select an item to add in order</FormHelperText>}
          <TextField
            fullWidth
            type="number"
            inputProps={{ min: 1 }}
            margin="normal"
            id="quantity"
            label="Quantity"
            className={classes.textField}
            value={newItem.quantity}
            onChange={this.handleNewItemChange('quantity')}
            error={!!errors.quantity}
            helperText={errors.quantity}
          />
          <Spacer />
          <Tooltip title="Add Item">
            <Fab size="small" color="secondary" onClick={this.addItem}>
              <AddIcon />
            </Fab>
          </Tooltip>
          <Spacer />
          {order.items.map((item, index) => (
            <Card key={item.id} className={classes.card} >
              <CardContent>
                <div className={classes.itemHeader}>
                  <Typography variant="subtitle1">
                    {item.title}
                  </Typography>
                  <IconButton title="Remove Item" onClick={() => this.removeItem(index)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
                <div className={classes.itemHeader}>
                  <div>
                    <Typography variant="caption" color="textSecondary">
                      {item.quantity} @ {item.price}
                    </Typography>
                  </div>
                  <Typography variant="caption" color="textSecondary">
                    {item.price * item.quantity} {restaurant.currency}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          ))}
          <Spacer />
          <div className={classes.itemHeader}>
            <Typography variant="subtitle1">
              Total
            </Typography>
            <Typography variant="subtitle2">
              {order.items.map(i => i.price * i.quantity).reduce((pv, cv) => pv + cv, 0)} {restaurant.currency}
            </Typography>
          </div>
          {submitted && order.items.length === 0 && <FormHelperText error={true}>Your order contains no items, please add.</FormHelperText>}
          {updatingError && <ErrorMessage error={parseGqlError(updatingError)} />}
          <div className={classes.actionBar}>
            <Button variant="contained" onClick={() => history.push(match.params.id ? '/orders/view/' + order.id : '/orders/list')}>
              Cancel
            </Button>
            {updating ? <CircularProgress size={30} /> :
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            }
          </div>
        </fieldset>
      </form>
    );
  }
}

export default withStyles(styles)(EditOrder);