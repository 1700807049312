import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles, Table, TableRow, TableCell, TableBody, Button, CircularProgress, LinearProgress, Card, CardContent, CardHeader, CardActions, Typography
} from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { differenceInDays } from 'date-fns';

import { parseGqlError } from '../utils';
import { ErrorMessage, Spacer } from '../components';
import { cardHeader, cardActions } from '../globalStyles';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  cardHeader: cardHeader(theme),
  cardActions: cardActions(theme),
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  }
});

export const getBillingGql = gql`
  {
    getUser {
      id
      createdAt
      trialExpiration
      subscription {
        id
        cardNumber
        cardExpiration
        price
      }
    }
    getPackage {
      id
      price
    }
  }
`;

export const isTrialValid = (trialExpiration) => true; // new Date() <= new Date(trialExpiration);

class ViewBilling extends React.Component {
  trialCurrentDay({ createdAt }) {
    return differenceInDays(new Date(), createdAt);
  }
  trialTotalDays({ createdAt, trialExpiration }) {
    return differenceInDays(trialExpiration, createdAt);
  }
  trialPercentage(user) {
    return Math.round(this.trialCurrentDay(user) / this.trialTotalDays(user) * 100);
  }
  render() {
    const { classes } = this.props;
    return (
      <Query query={getBillingGql}>
        {({ loading, error, data: { getUser: user, getPackage: pkg } = {} }) => {
          if (loading) return (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          );
          if (error) return <ErrorMessage error={parseGqlError(error)} />;
          return (
            <div style={{ maxWidth: '600px' }}>
              {isTrialValid(user.trialExpiration) &&
                <>
                  <Card>
                    <CardHeader title="Trial Period" className={classes.cardHeader} />
                    <CardContent>
                      <Typography>
                        You have consumed {this.trialCurrentDay(user)} days out of {this.trialTotalDays(user)} days trial.
                    </Typography>
                    </CardContent>
                    <LinearProgress className={classes.progress} variant="determinate" value={this.trialPercentage(user)} />
                  </Card>
                  <Spacer />
                </>
              }
              {!isTrialValid(user.trialExpiration) && !user.subscription &&
                <>
                  <ErrorMessage error={`Your trial period of ${this.trialTotalDays(user)} days has been expired`} />
                  <Spacer />
                </>
              }
              {!user.subscription &&
                <Card>
                  <CardHeader title="Monthly Subscription" className={classes.cardHeader} />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography component="h3" variant="h3" color="textPrimary">
                        $10
                      </Typography>
                      &nbsp;
                      <Typography variant="h6" color="textSecondary"> / month</Typography>
                    </div>
                    <Typography variant="subtitle1">
                      <CheckIcon style={{ verticalAlign: 'middle' }} /> Zero Setup Fees
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon style={{ verticalAlign: 'middle' }} /> Zero Commission Per Order
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon style={{ verticalAlign: 'middle' }} /> Menu Management
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon style={{ verticalAlign: 'middle' }} /> Upto 3,000 orders each month
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon style={{ verticalAlign: 'middle' }} /> Upto 3,000 reservations each month
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon style={{ verticalAlign: 'middle' }} /> Receive Orders Online
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon style={{ verticalAlign: 'middle' }} /> New Order Notification
                    </Typography>
                    <Typography variant="subtitle1">
                      <CheckIcon style={{ verticalAlign: 'middle' }} /> WhatsApp Integration
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button fullWidth variant="contained" color="primary" component={Link} to="/billing/edit">
                      Purchase
                    </Button>
                  </CardActions>
                </Card>
              }
              {user.subscription &&
                <>
                  <Card>
                    <CardHeader
                      title="Monthly Subscription"
                      className={classes.cardHeader}
                    />
                    <CardContent>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Credit Card</TableCell>
                            <TableCell>{user.subscription.cardNumber}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Expiration</TableCell>
                            <TableCell>{user.subscription.cardExpiration}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Price</TableCell>
                            <TableCell>${user.subscription.price} / month</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                      <Button className={classes.edit} variant="outlined" size="small" component={Link} to="/billing/edit">Edit</Button>
                    </CardActions>
                  </Card>
                </>
              }
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(ViewBilling);