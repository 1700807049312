import React from 'react';
import {
  withStyles, Tab, Tabs, Paper, AppBar, Typography
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import InputIcon from '@material-ui/icons/Input';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HelpIcon from '@material-ui/icons/Help';

import logo from '../logo.png';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing.unit * 8,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing.unit * 2,
    }
  },
  logo: {
    marginBottom: '1rem'
  },
  copyright: {
    marginTop: '1rem'
  },
  body: {
    padding: theme.spacing.unit * 3,
    maxWidth: '600px'
  }
});

class Guest extends React.Component {
  routes = ['/signin', '/signup', '/forgot-password'];
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: this.getTabIndex(props)
    };
  }
  getTabIndex = (props) => {
    const { pathname } = props.location;
    const index = this.routes.findIndex(p => p === pathname);
    return index < 0 ? 0 : index;
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ tabIndex: this.getTabIndex(nextProps) })
  }
  onTabChange = (event, value) => {
    const { history } = this.props;
    this.setState({ tabIndex: value });
    history.push(this.routes[value]);
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <a className={classes.logo} title="Goto Home Page" href="https://servetreats.com"><img src={logo} alt="ServeTreats Logo" /></a>
        <Paper className={classes.paper}>
          <AppBar position="static" color="primary">
            <Tabs value={this.state.tabIndex} variant="fullWidth" onChange={this.onTabChange}>
              <Tab label="Sign In" icon={<InputIcon />} />
              <Tab label="Create Account" icon={<PersonAddIcon />} />
              <Tab label="Forgot Password" icon={<HelpIcon />} />
            </Tabs>
          </AppBar>
          <div className={classes.body}>
            {this.props.children}
          </div>
        </Paper>
        <Typography className={classes.copyright} variant="caption" color="textSecondary">Copyright &copy; ServeTreats</Typography>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Guest));
