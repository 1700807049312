import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles, Typography, Table, TableHead, TableRow, TableCell, TableBody, Card, CardHeader, CardContent, CircularProgress, Button, Chip } from '@material-ui/core';
import { Restaurant as RestaurantIcon, RestaurantMenu as RestaurantMenuIcon, ShoppingBasket as ShoppingBasketIcon, DateRange as DateRangeIcon } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { cardHeader, cardActions, tableResponsiveContainer, greenChip } from '../globalStyles';
import { Spacer, ErrorMessage } from '../components';
import { formatDate, parseGqlError } from '../utils'

export const getStatsGql = gql`
  {
    getOrdersStats {
      type
      status
      count
    }
    getReservationsStats {
      date
      count
    }
  }
`;

export const checkRestGql = gql`
  {
    getRestaurant {
      id
    }
  }
`;

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  button: {
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 3
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  cardHeader: cardHeader(theme),
  cardActions: cardActions(theme),
  tableResponsiveContainer: tableResponsiveContainer(theme),
  greenChip: greenChip(theme)
});

const getCount = (orders, status, type) => {
  const o = orders.find(o => o.status === status && o.type === type);
  return o ? o.count : 0;
}

const Home = ({ classes }) => (
  <Query query={checkRestGql}>
    {({ loading, error, data: { getRestaurant: hasRest } = {} }) => {
      if (loading) return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      );
      if (error) return <ErrorMessage error={parseGqlError(error)} />;
      if (!hasRest) {
        return (
          <Button color="primary" variant="outlined" size="small" className={classes.button} component={Link} to="/restaurant/view">
            <RestaurantIcon className={classes.leftIcon} /> Setup Restaurant
            </Button>
        );
      }
      return (
        <Query query={getStatsGql}>
          {({ loading, error, data: { getOrdersStats, getReservationsStats } = {} }) => {
            if (loading) return (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            );
            if (error) return <ErrorMessage error={parseGqlError(error)} />;
            return (
              <>
                <div>
                  <Button color="primary" variant="outlined" size="small" className={classes.button} component={Link} to="/restaurant/view">
                    <RestaurantIcon className={classes.leftIcon} /> Setup Restaurant
                  </Button>
                  <Button color="primary" variant="outlined" size="small" className={classes.button} component={Link} to="/menu/view">
                    <RestaurantMenuIcon className={classes.leftIcon} /> Setup Menu
            </Button>
                  <Button color="primary" variant="outlined" size="small" className={classes.button} component={Link} to="/orders/add">
                    <ShoppingBasketIcon className={classes.leftIcon} /> Add Order
            </Button>
                  <Button color="primary" variant="outlined" size="small" className={classes.button} component={Link} to="/reservations/add">
                    <DateRangeIcon className={classes.leftIcon} /> Add Reservation
            </Button>
                </div>
                <div style={{ maxWidth: '600px' }}>
                  <Card className={classes.tableResponsiveContainer}>
                    <CardHeader title="Orders Statistics" className={classes.cardHeader} />
                    <CardContent>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Dine In</TableCell>
                            <TableCell>Home Delivery</TableCell>
                            <TableCell>Take Away</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell data-title="Status"><Chip label="Pending" /></TableCell>
                            <TableCell data-title="Dine In">{getCount(getOrdersStats, 'PENDING', 'DINE_IN')}</TableCell>
                            <TableCell data-title="Home Delivery">{getCount(getOrdersStats, 'PENDING', 'HOME_DELIVERY')}</TableCell>
                            <TableCell data-title="Take Away">{getCount(getOrdersStats, 'PENDING', 'TAKE_AWAY')}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell data-title="Status"><Chip label="In Progress" color="primary" /></TableCell>
                            <TableCell data-title="Dine In">{getCount(getOrdersStats, 'SERVED', 'DINE_IN')}</TableCell>
                            <TableCell data-title="Home Delivery">{getCount(getOrdersStats, 'DISPATCHED', 'HOME_DELIVERY')}</TableCell>
                            <TableCell data-title="Take Away">{getCount(getOrdersStats, 'READY', 'TAKE_AWAY')}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell data-title="Status"><Chip label="Complete" className={classes.greenChip} /></TableCell>
                            <TableCell data-title="Dine In">{getCount(getOrdersStats, 'COMPLETE', 'DINE_IN')}</TableCell>
                            <TableCell data-title="Home Delivery">{getCount(getOrdersStats, 'COMPLETE', 'HOME_DELIVERY')}</TableCell>
                            <TableCell data-title="Take Away">{getCount(getOrdersStats, 'COMPLETE', 'TAKE_AWAY')}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell data-title="Status"><Chip label="Canceled" color="secondary" /></TableCell>
                            <TableCell data-title="Dine In">{getCount(getOrdersStats, 'CANCELED', 'DINE_IN')}</TableCell>
                            <TableCell data-title="Home Delivery">{getCount(getOrdersStats, 'CANCELED', 'HOME_DELIVERY')}</TableCell>
                            <TableCell data-title="Take Away">{getCount(getOrdersStats, 'CANCELED', 'TAKE_AWAY')}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                  <Spacer />
                  <Card className={classes.tableResponsiveContainer}>
                    <CardHeader title="Reservations Statistics" className={classes.cardHeader} />
                    <CardContent>
                      {getReservationsStats.length === 0 ?
                        <Typography>
                          No up coming reservations
                  </Typography>
                        :
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Count</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {getReservationsStats.map(res => (
                              <TableRow key={res.date}>
                                <TableCell data-title="Date">{formatDate(res.date)}</TableCell>
                                <TableCell data-title="Count">{res.count}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      }
                    </CardContent>
                  </Card>
                </div>
              </>
            );
          }}
        </Query>
      );
    }}
  </Query>
);

export default withStyles(styles)(Home);
