import React from 'react';
import { withStyles, Chip, Typography } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';

export const Spacer = withStyles(theme => ({
  spacer: {
    marginTop: theme.spacing.unit * 3
  }
}))(({ classes }) => (
  <div className={classes.spacer} />
));

export const ErrorMessage = withStyles(theme => ({
  error: {
    marginTop: theme.spacing.unit * 3
  }
}))(({ classes, error }) => (
  <Typography className={classes.error} color="error">
    <ErrorIcon style={{ verticalAlign: 'middle' }} /> {error}
  </Typography>
));

export const StatsCountChip = ({ label }) => 
  <Chip variant="outlined" label={label} style={{ marginRight: '1rem', marginBottom: '1rem' }} />;

