import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles, TextField, Typography, Button, CircularProgress, Card
} from '@material-ui/core';
import gql from 'graphql-tag';

import { apolloClient } from '../apolloClient';
import { getUserGql } from './ViewAccount';
import { validate } from '../validate';
import { parseGqlError } from '../utils';
import { ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    maxWidth: '600px',
    padding: theme.spacing.unit * 3
  },
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const updateUserGql = gql`
  mutation updateUser($firstName: String!, $lastName: String!) {
    updateUser(firstName: $firstName, lastName: $lastName) {
      id, firstName, lastName
    }
  }
`;

class EditAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updating: false,
      loadingError: null,
      updateError: null,
      fields: {
        firstName: '',
        lastName: '',
      },
      submitted: false,
    };
  }
  async componentDidMount() {
    try {
      const { data: { getUser: user } } = await apolloClient.query({ query: getUserGql });
      this.setState({ loading: false, fields: user });
    } catch (error) {
      this.setState({ loading: false, loadingError: error });
    }
  }
  handleChange = name => event => {
    const { fields } = this.state;
    const newFields = {
      ...fields,
      [name]: event.target.value,
    };
    this.setState({ fields: newFields });
  };
  setUserHandler = async (event) => {
    const { history } = this.props;
    event.preventDefault();
    this.setState({ submitted: true, updateError: null, loading: false });
    const errorField = Object.keys(this.state.fields).find(f => this.validation[f] && validate(this.validation[f], this.state.fields[f]));
    if (errorField) {
      return document.getElementById(errorField).focus();
    }
    try {
      this.setState({ updating: true });
      const { firstName, lastName } = this.state.fields;
      await apolloClient.mutate({
        mutation: updateUserGql,
        variables: { firstName, lastName }
      });
      this.setState({ submitted: false, updating: false });
      history.push('/account/view');
    } catch (err) {
      this.setState({ updateError: err, updating: false, submitted: false });
    }
  }
  validation = {
    firstName: ['required', 'isAlphaSpace', ['isByteLength', { min: 3, max: 50 }]],
    lastName: ['required', 'isAlphaSpace', ['isByteLength', { min: 3, max: 50 }]]
  }
  render() {
    const { classes } = this.props;
    const { loading, updating, loadingError, updateError, fields, submitted } = this.state;
    if (loading) return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
    if (loadingError) return <ErrorMessage error={parseGqlError(loadingError)} />;
    const errors = {};
    Object.keys(fields).forEach(fieldName => {
      errors[fieldName] = submitted && this.validation[fieldName] && validate(this.validation[fieldName], fields[fieldName]);
    });
    return (
      <Card className={classes.card}>
        <form noValidate onSubmit={this.setUserHandler}>
          <fieldset disabled={updating} style={{ border: 'none' }}>
          <Typography variant="h6">
            Update Account Information
          </Typography>
          <TextField
            required
            fullWidth
            type="input"
            margin="normal"
            autoFocus
            id="firstName"
            label="First Name"
            className={classes.textField}
            value={fields.firstName}
            onChange={this.handleChange('firstName')}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            required
            fullWidth
            type="input"
            margin="normal"
            id="lastName"
            label="Last Name"
            className={classes.textField}
            value={fields.lastName}
            onChange={this.handleChange('lastName')}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
            {updateError && <ErrorMessage error={parseGqlError(updateError)} />}
            <div className={classes.actionBar}>
              <Button variant="contained" component={Link} to="/account/view">
                Cancel
            </Button>
              {updating ? <CircularProgress size={30} /> :
                <Button type="submit" variant="contained" color="primary">
                  Save
              </Button>
              }
            </div>
          </fieldset>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(EditAccount);