import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import EventEmitter from 'eventemitter3';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { CssBaseline, MuiThemeProvider, createMuiTheme } from '@material-ui/core';

import GuestLayout from './layouts/Guest';
import UserLayout from './layouts/User';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';

import Dashboard from './pages/Dashboard';
import ViewRestaurant from './pages/ViewRestaurant';
import EditRestaurant from './pages/EditRestaurant';
import ViewMenu from './pages/ViewMenu';
import EditMenu from './pages/EditMenu';
import ListOrders from './pages/ListOrders';
import ViewOrder from './pages/ViewOrder';
import EditOrder from './pages/EditOrder';
import ListReservations from './pages/ListReservations';
import ViewReservation from './pages/ViewReservation';
import EditReservation from './pages/EditReservation';
import ViewAccount from './pages/ViewAccount';
import EditAccount from './pages/EditAccount';
import ChangeEmail from './pages/ChangeEmail';
import ChangePassword from './pages/ChangePassword';
import ViewBilling from './pages/ViewBilling';
import EditBilling from './pages/EditBilling';

import { apolloClient } from './apolloClient';

export const appEvents = new EventEmitter();

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

const GuestRoute = ({ component: Component, signedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !signedIn ?
      <GuestLayout>
        <Component {...props} />
      </GuestLayout>
      :
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    }
  />
);

const UserRoute = ({ component: Component, signedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      signedIn ?
      <UserLayout>
        <Component {...props} />
      </UserLayout>
      :
      <Redirect
        to={{
          pathname: '/signin',
          state: { from: props.location }
        }}
      />
    }
  />
);

class App extends React.Component {

  state = {
    signedIn: !!sessionStorage.getItem('auth')
  }

  componentDidMount() {
    appEvents.on('signIn', this.signIn);
    appEvents.on('signOut', this.signOut);
  }

  componentWillUnmount() {
    appEvents.removeListener('signIn', this.signIn);
    appEvents.removeListener('signOut', this.signOut);
  }

  signIn = async (userId, token, callback) => {
    const auth = `${userId} ${token}`;
    sessionStorage.setItem('auth', auth);
    this.setState({ signedIn: true });
    if (callback) callback();
  }

  signOut = () => {
    this.setState({ signedIn: false });
    sessionStorage.clear();
    apolloClient.clearStore();
  }

  render() {
    const { signedIn } = this.state;
    return (
      <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <GuestRoute signedIn={signedIn} exact path="/signin" component={Signin} />
            <GuestRoute signedIn={signedIn} exact path="/signup" component={Signup} />
            <GuestRoute signedIn={signedIn} exact path="/forgot-password" component={ForgotPassword} />
            <UserRoute signedIn={signedIn} exact path="/" component={Dashboard} />
            <UserRoute signedIn={signedIn} exact path="/restaurant/view" component={ViewRestaurant} />
            <UserRoute signedIn={signedIn} exact path="/restaurant/edit" component={EditRestaurant} />
            <UserRoute signedIn={signedIn} exact path="/menu/view" component={ViewMenu} />
            <UserRoute signedIn={signedIn} exact path="/menu/add" component={EditMenu} />
            <UserRoute signedIn={signedIn} exact path="/menu/edit/:id" component={EditMenu} />
            <UserRoute signedIn={signedIn} exact path="/orders/list" component={ListOrders} />
            <UserRoute signedIn={signedIn} exact path="/orders/view/:id" component={ViewOrder} />
            <UserRoute signedIn={signedIn} exact path="/orders/add" component={EditOrder} />
            <UserRoute signedIn={signedIn} exact path="/orders/edit/:id" component={EditOrder} />
            <UserRoute signedIn={signedIn} exact path="/reservations/list" component={ListReservations} />
            <UserRoute signedIn={signedIn} exact path="/reservations/view/:id" component={ViewReservation} />
            <UserRoute signedIn={signedIn} exact path="/reservations/add" component={EditReservation} />
            <UserRoute signedIn={signedIn} exact path="/reservations/edit/:id" component={EditReservation} />
            <UserRoute signedIn={signedIn} exact path="/account/view" component={ViewAccount} />
            <UserRoute signedIn={signedIn} exact path="/account/edit" component={EditAccount} />
            <UserRoute signedIn={signedIn} exact path="/account/change-email" component={ChangeEmail} />
            <UserRoute signedIn={signedIn} exact path="/account/change-password" component={ChangePassword} />
            <UserRoute signedIn={signedIn} exact path="/billing/view" component={ViewBilling} />
            <UserRoute signedIn={signedIn} exact path="/billing/edit" component={EditBilling} />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    </ApolloProvider>
    );
  }

}

export default App;
