import { ApolloClient, } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from 'apollo-link-error';
import { getMainDefinition } from 'apollo-utilities';

import { appEvents } from './App';

console.log(process.env.NODE_ENV);

const httpUri = process.env.NODE_ENV === 'production' ? 'https://api.servetreats.com' : 'http://localhost:4000';
const wsUri = process.env.NODE_ENV === 'production' ? 'wss://api.servetreats.com/graphql' : 'ws://localhost:4000/graphql';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.find(e => e.extensions.code === 'UNAUTHENTICATED')) {
    appEvents.emit('signOut');
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const auth = sessionStorage.getItem('auth');
  if (auth) operation.setContext({ headers: { authorization: auth } });
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: httpUri,
  credentials: 'same-origin'
});

export const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    connectionParams: () => {
      return { Authorization: sessionStorage.getItem('auth') };
    }
  }
});

if (!sessionStorage.getItem('auth')) {
  // wsLink.subscriptionClient.close();
}

const transportLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    transportLink,
  ]),
  cache: new InMemoryCache()
});