import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles, TextField, Typography, Button, CircularProgress, Card
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Redirect } from 'react-router';

import { apolloClient } from '../apolloClient';
import { validate } from '../validate';
import { parseGqlError } from '../utils';
import { ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    maxWidth: '600px',
    padding: theme.spacing.unit * 3
  },
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const requestEmailChangeGql = gql`
  mutation requestEmailChange($newEmail: EmailAddress!) {
    requestEmailChange(newEmail: $newEmail)
  }
`;

const changeEmailGql = gql`
  mutation changeEmail($verificationCode: String!) {
    changeEmail(verificationCode: $verificationCode) {
      id, email
    }
  }
`;

class ChangeEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'request',
      loading: false,
      error: null,
      newEmail: '',
      verificationCode: '',
      submitted: false,
    };
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  requestEmailChangeHandler = async (event) => {
    event.preventDefault();
    this.setState({ submitted: true, error: null, loading: false });
    const emailError = validate(this.validation.newEmail, this.state.newEmail);
    if (emailError) {
      return document.getElementById('newEmail').focus();
    }
    try {
      const { newEmail } = this.state;
      await apolloClient.mutate({
        mutation: requestEmailChangeGql,
        variables: { newEmail }
      });
      this.setState({ submitted: false, stage: 'verify' });
    } catch (err) {
      this.setState({ error: err, loading: false, submitted: false });
    }
  }
  changeEmailHandler = async (event) => {
    event.preventDefault();
    this.setState({ submitted: true, error: null, loading: false });
    const codeError = validate(this.validation.verificationCode, this.state.verificationCode);
    if (codeError) {
      return document.getElementById('verificationCode').focus();
    }
    try {
      const { verificationCode } = this.state;
      await apolloClient.mutate({
        mutation: changeEmailGql,
        variables: { verificationCode }
      });
      this.setState({ submitted: false, stage: 'done' });
    } catch (err) {
      this.setState({ error: err, loading: false, submitted: false });
    }
  }
  validation = {
    newEmail: ['required', 'isEmail'],
    verificationCode: ['required']
  }
  requestEmailChangeView() {
    const { classes } = this.props;
    const { loading, error, newEmail, submitted } = this.state;
    const errors = {};
    errors.newEmail = submitted && validate(this.validation.newEmail, newEmail);
    return (
      <Card className={classes.card}>
        <form noValidate onSubmit={this.requestEmailChangeHandler}>
          <fieldset disabled={loading} style={{ border: 'none' }}>
          <Typography variant="h6">
            Change Email Address
          </Typography>
          <TextField
            required
            fullWidth
            type="input"
            margin="normal"
            autoFocus
            id="newEmail"
            label="New Email"
            className={classes.textField}
            value={newEmail}
            onChange={this.handleChange('newEmail')}
            error={!!errors.newEmail}
            helperText={errors.newEmail}
          />
            {error && <ErrorMessage error={parseGqlError(error)} />}
            <div className={classes.actionBar}>
              <Button variant="contained" component={Link} to="/account/view">
                Cancel
            </Button>
              {loading ? <CircularProgress size={30} /> :
                <Button type="submit" variant="contained" color="primary">
                  Next
              </Button>
              }
            </div>
          </fieldset>
        </form>
      </Card>
    );
  }
  changeEmailView() {
    const { classes } = this.props;
    const { loading, error, newEmail, verificationCode, submitted } = this.state;
    const errors = {};
    errors.verificationCode = submitted && validate(this.validation.verificationCode, verificationCode);
    return (
      <Card className={classes.card}>
        <form noValidate onSubmit={this.changeEmailHandler}>
          <fieldset disabled={loading} style={{ border: 'none' }}>
          <Typography variant="h6">
            Verify new email address
          </Typography>
          <Typography gutterBottom>
            Enter the verification code we sent to {newEmail}
          </Typography>
          <Typography color="textSecondary">
            If you don’t see it, check your spam folder.
          </Typography>
          <TextField
            required
            fullWidth
            type="input"
            margin="normal"
            autoFocus
            id="verificationCode"
            label="Verification Code"
            className={classes.textField}
            value={verificationCode}
            onChange={this.handleChange('verificationCode')}
            error={!!errors.verificationCode}
            helperText={errors.verificationCode}
          />
            {error && <ErrorMessage error={parseGqlError(error)} />}
            <div className={classes.actionBar}>
              <Button variant="contained" component={Link} to="/account/view">
                Cancel
            </Button>
              {loading ? <CircularProgress size={30} /> :
                <Button type="submit" variant="contained" color="primary">
                  Confirm
              </Button>
              }
            </div>
          </fieldset>
        </form>
      </Card>
    );
  }
  render() {
    const { stage } = this.state;
    if (stage === 'request') return this.requestEmailChangeView();
    else if (stage === 'verify') return this.changeEmailView();
    else if (stage === 'done') return (
      <Redirect
        to={{
          pathname: "/account",
          state: {
            msg: "You account email address has been changed successfully 🎉"
          }
        }} />
    );
  }
}

export default withStyles(styles)(ChangeEmail);