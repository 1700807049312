export const tableResponsiveContainer = theme => ({
  [theme.breakpoints.down('sm')]: {
    boxShadow: 'none',
    borderRadius: 0,
    backgroundColor: 'transparent',
    '& thead': {
      display: 'none'
    },
    '& tr': {
      display: 'block',
      height: 'auto',
      marginBottom: '1.6rem',
      boxShadow: theme.shadows[4],
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper
    },
    '& tr:last-child': {
      marginBottom: 0
    },
    '& td[data-title]:before': {
      content: 'attr(data-title)',
      float: 'left'
    },
    '& td': {
      display: 'block',
      textAlign: 'right',
      padding: '1rem'
    }
  }
});

export const actionsBarStyle = theme => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  width: '400px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'space-between'
  },
  '& button': {
    [theme.breakpoints.up('sm')]: {
      marginRight: '1rem'
    }
  },
  '@media print': {
    display: 'none'
  }
});

export const cardHeader = theme => ({
  backgroundColor: theme.palette.grey[200],
});

export const cardActions = theme => ({
  padding: theme.spacing.unit * 2,
});

export const greenChip = theme => ({
  backgroundColor: 'green',
  color: 'white'
});
