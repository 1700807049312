import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles, TextField, Typography, Button, CircularProgress, IconButton, Card, CardContent, Fab, Tooltip
} from '@material-ui/core';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import gql from 'graphql-tag';
import { assocPath, range, flatten, dissoc } from 'ramda';

import { apolloClient } from '../apolloClient';
import { getMenuCategoriesGql } from './ViewMenu';
import { validate } from '../validate';
import { parseGqlError, randomId } from '../utils';
import { ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    marginTop: theme.spacing.unit * 3
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const getMenuCategoryGql = gql`
  query getMenuCategory($id: ID!) {
    getMenuCategory(id: $id) {
      id, title, description, items {
        id, title, description, price
      }
    }
  }
`;

const addMenuCategoryGql = gql`
  mutation addMenuCategory($title: String!, $description: String!, $items: [MenuItemInput]!) {
    addMenuCategory(title: $title, description: $description, items: $items) {
      id, title, description, items {
        id, title, description, price
      }
    }
  }
`;

const updateMenuCategoryGql = gql`
  mutation updateMenuCategory($id: ID!, $title: String!, $description: String!, $items: [MenuItemInput]!) {
    updateMenuCategory(id: $id, title: $title, description: $description, items: $items) {
      id, title, description, items {
        id, title, description, price
      }
    }
  }
`;

class EditMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingError: null,
      updating: false,
      updatingError: null,
      category: {
        title: '',
        description: '',
        items: [
          this.genItem()
        ]
      },
      submitted: false,
    };
  }
  genItem = () => ({
    id: randomId(),
    title: '',
    description: '',
    price: ''
  })
  async componentDidMount() {
    const { id } = this.props.match.params;
    if (!id) return;
    try {
      this.setState({ loading: true });
      const { data: { getMenuCategory: category } } = await apolloClient.query({
        query: getMenuCategoryGql, variables: { id }
      });
      this.setState({ loading: false });
      if (category) {
        category.items.forEach((i) => {
          i.price = String(i.price);
        });
        this.setState({ category });
      }
    } catch (error) {
      this.setState({ loading: false, error });
    }
  }
  handleChange = path => event => {
    const category = assocPath(path, event.target.value, this.state.category);
    this.setState({ category });
  };
  upsertMenuCategory = async (event) => {
    const { history, match } = this.props;
    const { category } = this.state;
    event.preventDefault();
    this.setState({ submitted: true });
    if (validate(this.validation.title, category.title)) {
      return document.getElementById('categoryTitle').focus();
    }
    const specs = flatten(range(0, category.items.length).map(i => ([
      { id: `itemTitle-${i}`, rule: 'title', val: category.items[i].title },
      { id: `itemPrice-${i}`, rule: 'price', val: category.items[i].price }
    ])));
    for (const spec of specs) {
      if (validate(this.validation[spec.rule], spec.val)) {
        return document.getElementById(spec.id).focus();
      }
    }
    try {
      this.setState({ updating: true });
      const { id, title, description, items } = category;
      const categoryToSend = { title, description, items };
      if (match.params.id) categoryToSend.id = id;
      await apolloClient.mutate({
        mutation: match.params.id ? updateMenuCategoryGql : addMenuCategoryGql,
        variables: {
          ...categoryToSend,
          items: categoryToSend.items.map(
            ({ title, description, price }) => (dissoc('id', { title, description, price: Number(price) }))
          )
        },
        update: (proxy, { data: { addMenuCategory, updateMenuCategory } }) => {
          if (addMenuCategory) {
            const { getMenuCategories } = proxy.readQuery({ query: getMenuCategoriesGql });
            getMenuCategories.push(addMenuCategory);
            proxy.writeQuery({ query: getMenuCategoriesGql, data: { getMenuCategories } });
          }
        }
      });
      this.setState({ submitted: false, updating: false });
      history.push('/menu/view');
    } catch (err) {
      this.setState({ submitted: false, updating: false, updatingError: err });
    }
  }
  validation = {
    title: ['required', 'isAlphaSpace', ['isByteLength', { min: 3, max: 50 }]],
    price: ['required', 'isPrice']
  }
  addItem = () => {
    const { category } = this.state;
    category.items.push(this.genItem());
    this.setState({ category });
  }
  removeItem = (index) => {
    const { category } = this.state;
    category.items.splice(index, 1);
    this.setState({ category });
  }
  render() {
    const { classes } = this.props;
    const { loading, loadingError, updating, updatingError, category, submitted } = this.state;
    if (loading) return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
    if (loadingError) return <ErrorMessage error={parseGqlError(loadingError)} />;
    const errors = { items: category.items.map(() => ({})) };
    errors.title = submitted && validate(this.validation.title, category.title);
    category.items.forEach((item, index) => {
      errors.items[index].title = submitted && validate(this.validation.title, item.title);
      errors.items[index].price = submitted && validate(this.validation.price, item.price);
    });
    return (
      <form noValidate style={{ maxWidth: '600px' }} onSubmit={this.upsertMenuCategory}>
        <fieldset disabled={updating} style={{ border: 'none' }}>
          <Typography variant="h6">
            Add Menu Category with Items
          </Typography>
          <TextField
            fullWidth
            required
            type="input"
            margin="normal"
            autoFocus
            id="categoryTitle"
            label="Category title"
            placeholder="e.g. Desserts"
            className={classes.textField}
            value={category.title}
            onChange={this.handleChange(['title'])}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            fullWidth
            type="input"
            margin="normal"
            id="categoryDescription"
            label="Category description"
            className={classes.textField}
            value={category.description}
            onChange={this.handleChange(['description'])}
            error={!!errors.description}
            helperText={errors.description}
          />
          {category.items.map((item, index) => (
            <Card key={item.id} className={classes.card} >
              <CardContent>
                <div className={classes.itemHeader}>
                  <Typography variant="subtitle1" color="textSecondary">
                    {index + 1}
                  </Typography>
                  {category.items.length > 1 &&
                    <IconButton title="Remove Item" onClick={() => this.removeItem(index)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                </div>
                <TextField
                  fullWidth
                  required
                  type="input"
                  margin="normal"
                  id={`itemTitle-${index}`}
                  label="Item title"
                  placeholder="e.g. Russian Salad"
                  className={classes.textField}
                  value={item.title}
                  onChange={this.handleChange(['items', index, 'title'])}
                  error={!!errors.items[index].title}
                  helperText={errors.items[index].title}
                />
                <TextField
                  fullWidth
                  type="input"
                  margin="normal"
                  id={`itemDescription-${index}`}
                  label="Item description"
                  className={classes.textField}
                  value={item.description}
                  onChange={this.handleChange(['items', index, 'description'])}
                  error={!!errors.items[index].description}
                  helperText={errors.items[index].description}
                />
                <TextField
                  fullWidth
                  required
                  type="input"
                  margin="normal"
                  id={`itemPrice-${index}`}
                  label="Price"
                  placeholder="Complete price including any taxes"
                  className={classes.textField}
                  value={item.price}
                  onChange={this.handleChange(['items', index, 'price'])}
                  error={!!errors.items[index].price}
                  helperText={errors.items[index].price}
                />
              </CardContent>
            </Card>
          ))}
          {updatingError && <ErrorMessage error={parseGqlError(updatingError)} />}
          <div className={classes.actionBar}>
            <Button variant="contained" component={Link} to="/menu/view">
              Cancel
            </Button>
            <Tooltip title="Add Item">
              <Fab size="small" color="secondary" onClick={this.addItem}>
                <AddIcon />
              </Fab>
            </Tooltip>
            {updating ? <CircularProgress size={30} /> :
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            }
          </div>
        </fieldset>
      </form>
    );
  }
}

export default withStyles(styles)(EditMenu);