import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles, TextField, Typography, Button, CircularProgress, Card
} from '@material-ui/core';
import gql from 'graphql-tag';

import { pick } from 'ramda';
import { apolloClient } from '../apolloClient';
import { validate } from '../validate';
import { parseGqlError } from '../utils';
import { ErrorMessage } from '../components';
import { getBillingGql } from './ViewBilling';
import { getUserGql } from '../layouts/User';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    maxWidth: '600px',
    padding: theme.spacing.unit * 3
  },
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const getUserSubscriptionGql = gql`
  {
    getUser {
      subscription {
        id
        cardNumber
        cardExpiration
      }
    }
  }
`;

const setSubscriptionInfoGql = gql`
  mutation setSubscriptionInfo($cardNumber: String!, $cardExpiration: String!, $cardCode: String!) {
    setSubscriptionInfo(cardNumber: $cardNumber, cardExpiration: $cardExpiration, cardCode: $cardCode) {
      id
      cardNumber
      cardExpiration
      price
    }
  }
`;

class EditBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingError: null,
      updateError: null,
      oldSubscription: null,
      subscription: {
        cardNumber: '',
        cardExpiration: '',
        cardCode: '',
      },
      submitted: false,
    };
  }
  validation = {
    cardNumber: ['required', 'isCreditCard'],
    cardExpiration: ['required', 'isCCExpiry'],
    cardCode: ['required', 'isCVC']
  }
  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const { data: { getUser: user } } = await apolloClient.query({
        query: getUserSubscriptionGql
      });
      this.setState({
        loading: false
      });
      if (user.subscription) {
        this.setState({ oldSubscription: pick(['cardNumber', 'cardExpiration'], user.subscription) });
      }
    } catch (error) {
      this.setState({ loading: false, loadingError: error });
    }
  }
  handleChange = prop => event => {
    const { subscription } = this.state;
    this.setState({
      subscription: {
        ...subscription,
        [prop]: event.target.value
      }
    });
  };
  setSubscriptionHandler = async (event) => {
    event.preventDefault();
    const { history } = this.props;
    const { subscription } = this.state;
    this.setState({ submitted: true });
    const fieldNames = Object.keys(this.validation);
    const errorField = fieldNames.find(f => validate(this.validation[f], subscription[f]));
    if (errorField) {
      return document.getElementById(errorField).focus();
    }
    try {
      this.setState({ updating: true });
      await apolloClient.mutate({
        mutation: setSubscriptionInfoGql,
        variables: { ...subscription },
        refetchQueries: [{ query: getBillingGql }, { query: getUserGql }],
        awaitRefetchQueries: true
      });
      this.setState({ submitted: false, updating: false });
      history.push('/billing/view');
    } catch (err) {
      this.setState({ submitted: false, updating: false, updateError: err });
    }
  }
  render() {
    const { classes } = this.props;
    const { loading, loadingError, updateError, oldSubscription, subscription, submitted } = this.state;
    if (loading) return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
    if (loadingError) return <ErrorMessage error={parseGqlError(loadingError)} />;
    const errors = {};
    Object.keys(this.validation).forEach(fieldName => {
      errors[fieldName] = submitted && validate(this.validation[fieldName], subscription[fieldName]);
    });
    return (
      <Card className={classes.card}>
        <form noValidate onSubmit={this.setSubscriptionHandler}>
          <fieldset disabled={loading} style={{ border: 'none' }}>
            <Typography variant="h6">
              {oldSubscription ? 'Update' : 'Add'} Payment Details
            </Typography>
            {oldSubscription && <Typography variant="subtitle1" color="textSecondary">
              This will replace your old credit card
            </Typography>}
            <TextField
              required
              fullWidth
              autoFocus
              type="input"
              margin="normal"
              id="cardNumber"
              label="Card Number"
              className={classes.textField}
              value={subscription.cardNumber}
              onChange={this.handleChange('cardNumber')}
              error={!!errors.cardNumber}
              helperText={errors.cardNumber}
            />
            <TextField
              fullWidth
              required
              type="input"
              margin="normal"
              id="cardExpiration"
              label="Expiration Date"
              className={classes.textField}
              value={subscription.cardExpiration}
              onChange={this.handleChange('cardExpiration')}
              error={!!errors.cardExpiration}
              helperText={errors.cardExpiration}
              placeholder="MM/YY"
            />
            <TextField
              required
              fullWidth
              type="input"
              margin="normal"
              id="cardCode"
              label="CVC"
              className={classes.textField}
              value={subscription.cardCode}
              onChange={this.handleChange('cardCode')}
              error={!!errors.cardCode}
              helperText={errors.cardCode}
              placeholder="last three digits of the number that appears on the back of your card"
            />
            {updateError && <ErrorMessage error={parseGqlError(updateError)} />}
            <div className={classes.actionBar}>
              <Button variant="contained" component={Link} to="/billing/view/">
                Cancel
              </Button>
              {loading ? <CircularProgress size={30} /> :
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              }
            </div>
          </fieldset>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(EditBilling);