import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles, TextField, Typography, Button, CircularProgress, Card
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Redirect } from 'react-router';

import { apolloClient } from '../apolloClient';
import { validate } from '../validate';
import { parseGqlError } from '../utils';
import { ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    maxWidth: '600px',
    padding: theme.spacing.unit * 3
  },
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const changePasswordGql = gql`
  mutation changePassword($newPassword: String!) {
    changePassword(newPassword: $newPassword)
  }
`;

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      newPassword: '',
      submitted: false,
      done: false
    };
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };
  changePasswordHandler = async (event) => {
    event.preventDefault();
    this.setState({ submitted: true, error: null, loading: false });
    const passwordError = validate(this.validation.newPassword, this.state.newPassword);
    if (passwordError) {
      return document.getElementById('newPassword').focus();
    }
    try {
      const { newPassword } = this.state;
      await apolloClient.mutate({
        mutation: changePasswordGql,
        variables: { newPassword }
      });
      this.setState({ submitted: false, loading: false, done: true });
    } catch (err) {
      this.setState({ error: err, loading: false, submitted: false });
    }
  }
  validation = {
    newPassword: ['required', ['isByteLength', { min: 8, max: 50 }], 'isAlphanumericSymbolic']
  }
  render() {
    const { classes } = this.props;
    const { loading, error, newPassword, submitted, done } = this.state;
    if (done) {
      return (
        <Redirect
          to={{
            pathname: "/account/view",
            state: {
              msg: "You account password has been changed successfully 🎉"
            }
          }} />
      );
    }
    const errors = {};
    errors.newPassword = submitted && validate(this.validation.newPassword, newPassword);
    return (
      <Card className={classes.card}>
        <form noValidate onSubmit={this.changePasswordHandler}>
          <fieldset disabled={loading} style={{ border: 'none' }}>
            <Typography variant="h6">
              Change Password
          </Typography>
            <TextField
              required
              fullWidth
              type="password"
              margin="normal"
              autoFocus
              id="newPassword"
              label="New Password"
              className={classes.textField}
              value={newPassword}
              onChange={this.handleChange('newPassword')}
              error={!!errors.newPassword}
              helperText={errors.newPassword}
            />
            {error && <ErrorMessage error={parseGqlError(error)} />}
            <div className={classes.actionBar}>
              <Button variant="contained" component={Link} to="/account/view">
                Cancel
            </Button>
              {loading ? <CircularProgress size={30} /> :
                <Button type="submit" variant="contained" color="primary">
                  Save
              </Button>
              }
            </div>
          </fieldset>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(ChangePassword);