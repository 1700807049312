import React from 'react';
import {
  withStyles, TextField, Typography, Button, CircularProgress
} from '@material-ui/core';
import { Redirect } from 'react-router';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { pick } from 'ramda';

import { validate } from '../validate';
import { parseGqlError } from '../utils';
import { ErrorMessage } from '../components';

const styles = theme => ({
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const createAccountGql = gql`
  mutation createAccount($firstName: String!, $lastName: String! $email: EmailAddress!, $password: String!) {
    createAccount(firstName: $firstName, lastName: $lastName email: $email, password: $password)
  }
`;

const verifyEmailGql = gql`
  mutation verifyEmail($email: EmailAddress!, $verificationCode: String!) {
    verifyEmail(email: $email, verificationCode: $verificationCode)
  }
`;

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        verificationCode: '',
      },
      submitted: false,
      stage: 'createAccount'
    };
  }
  validation = {
    firstName: ['required', 'isAlpha', ['isByteLength', { min: 3, max: 50 }]],
    lastName: ['required', 'isAlpha', ['isByteLength', { min: 3, max: 50 }]],
    email: ['required', 'isEmail'],
    password: ['required', ['isByteLength', { min: 8, max: 50 }], 'isAlphanumericSymbolic'],
    verificationCode: ['required']
  }
  handleChange = name => event => {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [name]: event.target.value,
      }
    });
  };
  createAccount = createAccountMutation => async (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const fields = Object.keys(this.state.fields).filter(f => f !== 'verificationCode');
    const errorField = fields.find(field => validate(this.validation[field], this.state.fields[field]));
    if (errorField) {
      return document.getElementById(errorField).focus();
    }
    try {
      const user = pick(fields, this.state.fields);
      await createAccountMutation({ variables: user });
      this.setState({ stage: 'verifyEmail' });
    } catch (err) {
    } finally {
      this.setState({ submitted: false });
    }
  }
  verifyEmail = verifyEmailMutation => async (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    if (validate(this.validation.verificationCode, this.state.fields.verificationCode)) {
      document.getElementById('verificationCode').focus();
      return;
    }
    this.setState({ error: null, loading: true });
    try {
      const { email, verificationCode } = this.state.fields;
      await verifyEmailMutation({ variables: { email, verificationCode } });
      this.setState({ stage: 'done' });
    } catch (err) {
    } finally {
      this.setState({ submitted: false });
    }
  }
  createAccountView = () => {
    const { classes } = this.props;
    const { submitted, fields } = this.state;
    const errors = {};
    Object.keys(fields).filter(f => f !== 'verificationCode').forEach(fieldName => {
      errors[fieldName] = submitted && validate(this.validation[fieldName], fields[fieldName]);
    });
    return (
      <Mutation mutation={createAccountGql} fetchPolicy="no-cache">
        {(createAccountMutation, { loading, error }) => (
          <form noValidate onSubmit={this.createAccount(createAccountMutation)}>
            <fieldset disabled={loading} style={{ border: 'none' }}>
              <Typography variant="subtitle1">
                Fill details below to create new account
              </Typography>
              <TextField
                required
                fullWidth
                type="input"
                margin="normal"
                autoFocus
                id="firstName"
                label="First Name"
                className={classes.textField}
                value={fields.firstName}
                onChange={this.handleChange('firstName')}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
              <TextField
                required
                fullWidth
                type="input"
                margin="normal"
                id="lastName"
                label="Last Name"
                className={classes.textField}
                value={fields.lastName}
                onChange={this.handleChange('lastName')}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
              <TextField
                required
                fullWidth
                type="input"
                margin="normal"
                id="email"
                label="Email"
                className={classes.textField}
                value={fields.email}
                onChange={this.handleChange('email')}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                required
                fullWidth
                type="password"
                margin="normal"
                id="password"
                label="Password"
                className={classes.textField}
                value={fields.password}
                onChange={this.handleChange('password')}
                error={!!errors.password}
                helperText={errors.password || 'Use 8 or more characters with a mix of letters, numbers & symbols'}
              />
              {error && <ErrorMessage error={parseGqlError(error)} />}
              <div className={classes.actionBar}>
                <Typography color="textSecondary">
                  By signing up, you agree to our <a href="https://servetreats.com/terms" target="_blank" rel="noopener noreferrer">Terms</a>
                </Typography>
                {loading ? <CircularProgress size={30} /> :
                  <Button type="submit" className={classes.signIn} variant="contained" color="primary">
                    Next
                  </Button>
                }
              </div>
            </fieldset>
          </form>
        )}
      </Mutation>
    );
  }
  verifyEmailView = () => {
    const { classes } = this.props;
    const { submitted, fields } = this.state;
    const errors = {
      verificationCode: submitted && validate(this.validation.verificationCode, fields.verificationCode)
    };
    return (
      <Mutation mutation={verifyEmailGql}>
        {(verifyEmailMutation, { loading, error }) => (
          <form noValidate onSubmit={this.verifyEmail(verifyEmailMutation)}>
            <fieldset disabled={loading} style={{ border: 'none' }}>
              <Typography variant="subtitle1" gutterBottom>
                Verify your email address
            </Typography>
              <Typography gutterBottom>
                Enter the verification code we sent to {this.state.fields.email}
              </Typography>
              <Typography color="textSecondary">
                If you don’t see it, check your spam folder.
              </Typography>
              <TextField
                required
                type="input"
                margin="normal"
                fullWidth
                autoFocus
                id="verificationCode"
                label="Verification Code"
                className={classes.textField}
                value={fields.verificationCode}
                onChange={this.handleChange('verificationCode')}
                error={!!errors.verificationCode}
                helperText={errors.verificationCode}
              />
              {error && <ErrorMessage error={parseGqlError(error)} />}
              <div className={classes.actionBar}>
                <span></span>
                {loading ? <CircularProgress size={30} /> :
                  <Button type="submit" className={classes.signIn} variant="contained" color="primary">
                    Next
                </Button>
                }
              </div>
            </fieldset>
          </form>
        )}
      </Mutation>
    );
  }
  render() {
    const { stage } = this.state;
    if (stage === 'createAccount') return this.createAccountView();
    else if (stage === 'verifyEmail') return this.verifyEmailView();
    else if (stage === 'done') return (
      <Redirect
        to={{
          pathname: "/signin",
          state: {
            msg: "Your account has been created successfully 🎉"
          }
        }} />
    );
  }
}

export default withStyles(styles)(Signin);