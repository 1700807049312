import React from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles, Table, Typography, TableRow, TableCell, TableBody, Button, CircularProgress, Card, CardContent, CardHeader, CardActions
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { parseGqlError } from '../utils';
import { ErrorMessage } from '../components';
import { cardHeader, cardActions } from '../globalStyles';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  cardHeader: cardHeader(theme),
  cardActions: cardActions(theme)
});

export const getUserGql = gql`
  {
    getUser {
      id, email, firstName, lastName, role
    }
  }
`;

class ViewAccount extends React.Component {
  render() {
    const { classes, location } = this.props;
    return (
      <Query query={getUserGql}>
        {({ loading, error, data: { getUser: user } = {} }) => {
          if (loading) return (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          );
          if (error) return <ErrorMessage error={parseGqlError(error)} />;
          return (
            <>
              {location.state && location.state.msg &&
                <Typography variant="subtitle1" style={{ marginBottom: '1rem' }}>
                  {location.state && location.state.msg}
                </Typography>}
              <Card style={{ maxWidth: '600px' }}>
                <CardHeader title="My Account" className={classes.cardHeader} />
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ width: '40%' }}>ID</TableCell>
                        <TableCell>{user.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>{user.firstName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Last Name</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell>{user.role && user.role.replace('USER_', '')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button variant="outlined" size="small" component={Link} to="/account/edit">Edit</Button>
                  <Button variant="outlined" size="small" component={Link} to="/account/change-email">Change Email</Button>
                  <Button variant="outlined" size="small" component={Link} to="/account/change-password">Change Password</Button>
                </CardActions>
              </Card>
            </>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(ViewAccount);