import React from 'react';
import {
  withStyles, TextField, Typography, Button, CircularProgress
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Redirect } from 'react-router';

import { apolloClient } from '../apolloClient';
import { validate } from '../validate';
import { parseGqlError } from '../utils';
import { ErrorMessage } from '../components';

const styles = {
  actionBar: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
};

const forgotPasswordGql = gql`
  mutation forgotPassword($email: EmailAddress!) {
    forgotPassword(email: $email)
  }
`;

const resetPasswordGql = gql`
  mutation resetPassword($email: EmailAddress!, $verificationCode: String!, $newPassword: String!) {
    resetPassword(email: $email, verificationCode: $verificationCode, newPassword: $newPassword)
  }
`;

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      verificationCode: '',
      newPassword: '',
      loading: false,
      updating: false,
      error: null,
      stage: 'forgotPassword'
    };
  }
  validation = {
    email: ['required', 'isEmail'],
    verificationCode: ['required'],
    newPassword: ['required', ['isByteLength', { min: 8, max: 50 }], 'isAlphanumericSymbolic']
  }
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  forgotPasswordHandler = async (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { email } = this.state;
    const emailError = validate(this.validation.email, email);
    if (emailError) {
      return document.getElementById('email').focus();
    }
    try {
      this.setState({ updating: true, error: null });
      await apolloClient.mutate({
        mutation: forgotPasswordGql,
        variables: { email }
      });
      this.setState({ stage: 'resetPassword' });
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ submitted: false, updating: false });
    }
  }
  resetPasswordHandler = async (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { newPassword } = this.state;
    if (validate(this.validation.newPassword, newPassword)) {
      document.getElementById('newPassword').focus();
      return;
    }
    try {
      this.setState({ updating: true, error: null });
      const { email, verificationCode, newPassword } = this.state;
      await apolloClient.mutate({
        mutation: resetPasswordGql,
        variables: { email, verificationCode, newPassword },
        fetchPolicy: 'no-cache'
      });
      this.setState({ stage: 'done' });
    } catch (err) {
      this.setState({ error: err });
    } finally {
      // this.setState({ submitted: false, updating: false });
    }
  }
  forgotPasswordView = () => {
    const { classes } = this.props;
    const { submitted, updating, error, email } = this.state;
    const emailError = submitted && validate(this.validation.email, email);
    return (
      <form noValidate onSubmit={this.forgotPasswordHandler}>
        <Typography variant="subtitle1">
          Reset password, in case you forgot current one
            </Typography>
        <TextField
          required
          fullWidth
          id="email"
          type="input"
          label="Email"
          margin="normal"
          autoFocus
          className={classes.textField}
          value={email}
          onChange={this.handleChange('email')}
          error={!!emailError}
          helperText={emailError}
        />
        {error && <ErrorMessage error={parseGqlError(error)} />}
        <div className={classes.actionBar}>
          <span></span>
          {updating ? <CircularProgress size={30} /> :
            <Button type="submit" className={classes.signIn} variant="contained" color="primary">
              Next
            </Button>
          }
        </div>
      </form>
    );
  }
  resetPasswordView = () => {
    const { classes } = this.props;
    const { submitted, updating, error, email, verificationCode, newPassword } = this.state;
    const errors = {};
    if (submitted) {
      errors.verificationCode = validate(this.validation.verificationCode, verificationCode);
      errors.newPassword = validate(this.validation.newPassword, newPassword);
    }
    return (
      <form noValidate onSubmit={this.resetPasswordHandler}>
        <Typography variant="subtitle1">
          Setup new password for your account
        </Typography>
        <Typography gutterBottom>
          Enter the verification code we sent to {email}
        </Typography>
        <Typography color="textSecondary">
          If you don’t see it, check your spam folder.
        </Typography>
        <TextField
          required
          type="input"
          margin="normal"
          fullWidth
          autoFocus
          id="verificationCode"
          label="Verification Code"
          className={classes.textField}
          value={verificationCode}
          onChange={this.handleChange('verificationCode')}
          error={!!errors.verificationCode}
          helperText={errors.verificationCode}
        />
        <TextField
          required
          fullWidth
          type="password"
          margin="normal"
          id="newPassword"
          label="Password"
          className={classes.textField}
          value={newPassword}
          onChange={this.handleChange('newPassword')}
          error={!!errors.newPassword}
          helperText={errors.newPassword || 'Use 8 or more characters with a mix of letters, numbers & symbols'}
        />
        {error && <ErrorMessage error={parseGqlError(error)} />}
        <div className={classes.actionBar}>
          <span></span>
          {updating ? <CircularProgress size={30} /> :
            <Button type="submit" className={classes.signIn} variant="contained" color="primary">
              Next
            </Button>
          }
        </div>
      </form>
    );
  }
  render() {
    const { stage } = this.state;
    if (stage === 'forgotPassword') return this.forgotPasswordView();
    else if (stage === 'resetPassword') return this.resetPasswordView();
    else if (stage === 'done') return (
      <Redirect
        to={{
          pathname: "/signin",
          state: {
            msg: "New password has been set successfully 🎉"
          }
        }} />
    );
  }
}

export default withStyles(styles)(Signin);