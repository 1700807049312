import React from 'react';
import {
  withStyles, TextField, Typography, Button, CircularProgress, Card, InputAdornment
} from '@material-ui/core';
import {
  DateRange as DateRangeIcon, AccessTime as AccessTimeIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon
} from '@material-ui/icons';
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import gql from 'graphql-tag';

import { clone } from 'ramda';
import { apolloClient } from '../apolloClient';
import { getStatsGql } from './Dashboard';
import { getReservationsDatesGql, getReservationsGql, getReservationsListDate } from './ListReservations';
import { getRestaurantInfoGql } from './ViewRestaurant';
import { validate } from '../validate';
import { parseGqlError, emptyStrToNull, nullToEmptyStr, getOnlyDate } from '../utils';
import { ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    maxWidth: '600px',
    padding: theme.spacing.unit * 3
  },
  actionBar: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
});

const getReservationGql = gql`
  query getReservation($id: ID!) {
    getReservation(id: $id) {
      id, datetime, persons, name, email, phone, notes, status
    }
  }
`;

const addReservationGql = gql`
  mutation addReservation($reservation: ReservationInput!) {
    addReservation(reservation: $reservation) {
      id, datetime, persons, name, email, phone, notes, status
    }
  }
`;

const updateReservationGql = gql`
  mutation updateReservation($id: ID!, $reservation: ReservationInput!) {
    updateReservation(id: $id, reservation: $reservation) {
      id, datetime, persons, name, email, phone, notes, status
    }
  }
`;

class EditReservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      reservation: {
        datetime: getReservationsListDate(),
        persons: '',
        name: '',
        email: '',
        phone: '',
        notes: '',
      },
      submitted: false,
    };
  }
  validation = {
    datetime: ['required'],
    persons: ['required'],
    name: ['required'],
    email: ['required', 'isEmail'],
    phone: ['required', 'isMobilePhone']
  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    try {
      const { data: { getRestaurant: restaurant } = {} } = await apolloClient.query({
        query: getRestaurantInfoGql
      });
      this.setState({ restaurant });
      if (id) {
        const { data: { getReservation: reservation } } = await apolloClient.query({
          query: getReservationGql, variables: { id }
        });
        this.setState({ reservation: nullToEmptyStr(clone(reservation)), oldDatetime: reservation.datetime });
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error });
    }
  }
  handleChange = prop => event => {
    const { reservation } = this.state;
    this.setState({
      reservation: {
        ...reservation,
        [prop]: event.target.value
      }
    });
  };
  handleDateChange = name => value => {
    this.setState({ reservation: { ...this.state.reservation, [name]: value } });
  }
  upsertReservation = async (event) => {
    const { history, match } = this.props;
    const { reservation } = this.state;
    event.preventDefault();
    this.setState({ submitted: true });
    const fieldNames = Object.keys(this.validation);
    const errorField = fieldNames.find(f => validate(this.validation[f], reservation[f]));
    if (errorField) {
      return document.getElementById(errorField).focus();
    }
    try {
      this.setState({ updating: true });
      const { id, datetime, persons, name, email, phone, notes } = reservation;
      const reservationToSend = emptyStrToNull({ datetime, persons: Number(persons), name, email, phone, notes });
      const { data: { addReservation, updateReservation } } = await apolloClient.mutate({
        mutation: match.params.id ? updateReservationGql : addReservationGql,
        variables: {
          id,
          reservation: reservationToSend
        },
        refetchQueries: [{ query: getStatsGql }],
        update: (proxy, { data: { addReservation, updateReservation } }) => {
          if (addReservation) {
            const { getReservations } = proxy.readQuery({ query: getReservationsGql, variables: { date: getOnlyDate(addReservation.datetime) } });
            getReservations.push(addReservation);
            proxy.writeQuery({ query: getReservationsGql, variables: { date: getOnlyDate(addReservation.datetime) }, data: { getReservations } });
          } else if (updateReservation && getOnlyDate(updateReservation.datetime) !== getOnlyDate(this.state.oldDatetime)) {
            const { getReservations } = proxy.readQuery({ query: getReservationsGql, variables: { date: getOnlyDate(this.state.oldDatetime) } });
            getReservations.splice(getReservations.findIndex(o => o.id === updateReservation.id ), 1);
            proxy.writeQuery({ query: getReservationsGql, variables: { date: getOnlyDate(this.state.oldDatetime) }, data: { getReservations } });
            try {
              const { getReservations: newReservations } = proxy.readQuery({ query: getReservationsGql, variables: { date: getOnlyDate(updateReservation.datetime) } });
              if (newReservations) {
                newReservations.push(updateReservation);
                proxy.writeQuery({ query: getReservationsGql, variables: { date: getOnlyDate(updateReservation.datetime) }, data: { getReservations: newReservations } });
              }
            } catch (err) {
              console.warn(err);
            }
          }
          const { getReservationsDates } = proxy.readQuery({ query: getReservationsDatesGql });
          const newDate = getOnlyDate((addReservation || updateReservation).datetime);
          if (!getReservationsDates.includes(newDate)) {
            getReservationsDates.push(newDate);
          }
          if (updateReservation) {
            const oldDate = getOnlyDate(this.state.oldDatetime);
            if (getReservationsDates.includes(oldDate)) {
              const { getReservations: oldDateReservations } = proxy.readQuery({ query: getReservationsGql, variables: { date: oldDate } });
              if (oldDateReservations.length === 0) {
                getReservationsDates.splice(getReservationsDates.findIndex(d => d === oldDate), 1);
              }
            }
          }
          proxy.writeQuery({ query: getReservationsDatesGql, data: { getReservationsDates } });
        }
      });
      this.setState({ submitted: false, updating: false });
      sessionStorage.setItem('reservationsListDate', new Date(datetime).toISOString());
      history.push('/reservations/view/' + (addReservation || updateReservation).id);
    } catch (err) {
      this.setState({ submitted: false, updating: false, updatingError: err });
    }
  }
  render() {
    const { classes, match, history } = this.props;
    const { loading, error, reservation, submitted, restaurant } = this.state;
    if (loading) return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
    if (error) return <ErrorMessage error={parseGqlError(error)} />;
    const errors = {};
    Object.keys(this.validation).forEach(fieldName => {
      errors[fieldName] = submitted && validate(this.validation[fieldName], reservation[fieldName]);
    });
    return (
      <Card className={classes.card}>
        <form noValidate onSubmit={this.upsertReservation}>
          <fieldset disabled={loading} style={{ border: 'none' }}>
            <Typography variant="h6">
              Add Reservation
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                fullWidth
                required
                disablePast={!match.params.id}
                showTodayButton
                format="dd MMM yyyy hh:mm a"
                dateRangeIcon={<DateRangeIcon />}
                timeIcon={<AccessTimeIcon />}
                leftArrowIcon={<KeyboardArrowLeftIcon />}
                rightArrowIcon={<KeyboardArrowRightIcon />}
                margin="normal"
                label="Date Time"
                id="datetime"
                value={reservation.datetime}
                onChange={this.handleDateChange('datetime')}
                error={!!errors.datetime}
                helperText={errors.datetime}
              />
            </MuiPickersUtilsProvider>
            <TextField
              required
              fullWidth
              type="number"
              inputProps={{ min: 1 }}
              margin="normal"
              id="persons"
              label="Number of Persons"
              className={classes.textField}
              value={reservation.persons}
              onChange={this.handleChange('persons')}
              error={!!errors.persons}
              helperText={errors.persons}
            />
            <TextField
              required
              fullWidth
              type="input"
              margin="normal"
              id="name"
              label="Name"
              className={classes.textField}
              value={reservation.name}
              onChange={this.handleChange('name')}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              fullWidth
              required
              type="input"
              margin="normal"
              id="email"
              label="Email"
              className={classes.textField}
              value={reservation.email}
              onChange={this.handleChange('email')}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              required
              fullWidth
              type="input"
              margin="normal"
              id="phone"
              label="Phone"
              className={classes.textField}
              value={reservation.phone}
              onChange={this.handleChange('phone')}
              error={!!errors.phone}
              helperText={errors.phone}
              InputProps={{
                startAdornment: <InputAdornment position="start">{restaurant.callingCode}</InputAdornment>,
              }}
            />
            <TextField
              fullWidth
              multiline
              rows="4"
              type="input"
              margin="normal"
              id="notes"
              label="Notes"
              className={classes.textField}
              value={reservation.notes}
              onChange={this.handleChange('notes')}
              error={!!errors.notes}
              helperText={errors.notes}
            />
            {error && <ErrorMessage error={parseGqlError(error)} />}
            <div className={classes.actionBar}>
              <Button variant="contained" onClick={() => history.push(match.params.id ? '/reservations/view/' + reservation.id : '/reservations/list')}>
                Cancel
              </Button>
              {loading ? <CircularProgress size={30} /> :
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              }
            </div>
          </fieldset>
        </form>
      </Card>
    );
  }
}

export default withStyles(styles)(EditReservation);