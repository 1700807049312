import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import {
  withStyles, Table, TableRow, TableCell, TableBody, Button, CircularProgress, Card, CardContent, CardHeader, CardActions
} from '@material-ui/core';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { parseGqlError, formatTime } from '../utils';
import { ErrorMessage } from '../components';
import { cardHeader, cardActions } from '../globalStyles';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  cardHeader: cardHeader(theme),
  cardActions: cardActions(theme)
});

export const getRestaurantGql = gql`
  {
    getRestaurant {
      id, name, tagline, description, address, city, country, phone, callingCode, currency, website,
      opening, closing, dineIn, takeAway, homeDelivery
    }
  }
`;

export const getRestaurantInfoGql = gql`
{
  getRestaurant {
    id, currency, callingCode
  }
}
`;

class ViewRestaurant extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Query query={getRestaurantGql}>
        {({ loading, error, data: { getRestaurant: restaurant } = {} }) => {
          if (loading) return (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          );
          if (error) return <ErrorMessage error={parseGqlError(error)} />;
          if (!restaurant) return <Redirect to="/restaurant/edit" />
          const orderOnlineLink = `https://online.servetreats.com/${restaurant.id}/${restaurant.name.replace(/ /g, '-')}`;
          return (
            <Card style={{ maxWidth: '600px' }}>
              <CardHeader title="My Restaurant" className={classes.cardHeader} />
              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ width: '40%' }}>Name</TableCell>
                      <TableCell>{restaurant.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Tagline</TableCell>
                      <TableCell>{restaurant.tagline}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>{restaurant.description}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Address</TableCell>
                      <TableCell>{restaurant.address}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>City</TableCell>
                      <TableCell>{restaurant.city}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Country</TableCell>
                      <TableCell>{restaurant.country}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Phone</TableCell>
                      <TableCell><a href={`tel:${restaurant.callingCode}${restaurant.phone}`}>{restaurant.callingCode}{restaurant.phone}</a></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Currency</TableCell>
                      <TableCell>{restaurant.currency}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Website</TableCell>
                      <TableCell>
                        <a href={`${restaurant.website}`} target="_blank" rel="noopener noreferrer">
                          {restaurant.website}
                        </a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Opening</TableCell>
                      <TableCell>{formatTime(restaurant.opening)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Closing</TableCell>
                      <TableCell>{formatTime(restaurant.closing)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Dine In</TableCell>
                      <TableCell>{restaurant.dineIn ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Take Away</TableCell>
                      <TableCell>{restaurant.takeAway ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Home Delivery</TableCell>
                      <TableCell>{restaurant.homeDelivery ? <CheckIcon /> : <CloseIcon />}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Order Online Link</TableCell>
                      <TableCell><a href={orderOnlineLink} target="_blank" rel="noopener noreferrer">{orderOnlineLink}</a></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button variant="outlined" size="small" component={Link} to="/restaurant/edit">Edit</Button>
              </CardActions>
            </Card>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(ViewRestaurant);