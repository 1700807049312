import validator from 'validator';

const isAlphanumericSymbolic = (value) => {
  const res = validator.isNumeric(value, { no_symbols: true }) || validator.isAlpha(value) || validator.isAlphanumeric(value);
  return !res;
};

const isAlphaSpace = (value) => /^[a-zA-Z]+( [a-zA-Z]+)*$/.test(value);
const isCCExpiry = (value) => /^(0[1-9]|1[0-2])\/([1-9][0-9])$/.test(value);
const isCVC = (value) => /^\d{3}$/.test(value);
const isMobilePhone = (value) => /^\d{6,12}$/.test(value);

const messages = {
  required: 'Should not be empty',
  isByteLength: ({ min, max }) => {
    if (min && max) return `Should be between ${min} to ${max} characters long`;
    if (min) return `Atleast ${min} characters required`;
    if (max) return `Maximum ${max} characters allowed`;
  },
  isEmail: 'Should be valid email',
  isMobilePhone: 'Should be valid phone',
  isAlpha: 'Should only contain letters',
  isAlphaSpace: 'Should only contain letters or spaces',
  isAlphanumericSymbolic: 'Should contain mix of letters, numbers & symbols',
  isURL: 'Should be valid URL',
  isPrice: 'Should be valid number greater than zero',
  isCreditCard: 'Should be valid credit card number',
  isCCExpiry: 'Should be valid month and year',
  isCVC: 'Should be valid 3 digit code'
};

export const validate = (funcs, value) => {
  for (const func of funcs) {
    if (typeof func === 'string') {
      if (func === 'required') {
        if (!value) return messages.required;
      } else if (func === 'isAlphanumericSymbolic') {
        if (value && !isAlphanumericSymbolic(value)) return messages.isAlphanumericSymbolic;
      } else if (func === 'isAlphaSpace') {
        if (value && !isAlphaSpace(value)) return messages.isAlphaSpace;
      } else if (func === 'isMobilePhone') {
        if (value && !isMobilePhone(value)) return messages.isMobilePhone;
      } else if (func === 'isURL') {
        if (value && !validator.isURL(value, { protocols: ['http', 'https'], require_protocol: true })) return messages.isURL;
      } else if (func === 'isPrice') {
        if (value && !validator.isFloat(value, { min: 0 })) return messages.isPrice;
      } else if (func === 'isCCExpiry') {
        if (value && !isCCExpiry(value)) return messages.isCCExpiry;
      } else if (func === 'isCVC') {
        if (value && !isCVC(value)) return messages.isCVC;
      } else if (value && !validator[func](value)) {
        return messages[func];
      }
    } else if (Array.isArray(func)) {
      if (value && !validator[func[0]](value, ...func.slice(1))) return messages[func[0]](...func.slice(1));
    }
  }
}
