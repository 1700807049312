import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  withStyles, Paper, Table, TableRow, TableCell, TableBody, Typography, Button, CircularProgress, Tooltip, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider
} from '@material-ui/core';
import { Check as CheckIcon, Edit as EditIcon, Close as CloseIcon, Delete as DeleteIcon, NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { apolloClient } from '../apolloClient';
import { getStatsGql } from './Dashboard';
import { getReservationsGql, ReservationStatusChip } from './ListReservations';
import { parseGqlError, formatDateTime, getOnlyDate } from '../utils';
import { actionsBarStyle } from '../globalStyles';
import { Spacer, ErrorMessage } from '../components';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 240px)',
  },
  card: {
    marginBottom: theme.spacing.unit * 3
  },
  actionsBarStyle: actionsBarStyle(theme),
  totalRow: {
    backgroundColor: theme.palette.background.default
  },
  deleteActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3
  },
  edit: {
    margin: theme.spacing.unit * 3
  }
});

export const getReservationGql = gql`
  query getReservation($id: ID!) {
    getReservation(id: $id) {
      id, datetime, persons, name, email, phone, notes, status
    }
    getRestaurant {
      id, callingCode
    }
  }
`;

class ViewReservations extends React.Component {
  state = {
    reservationToDelete: '',
    showDeleteDialog: false,
  }
  changeReservationStatus = async (id, status) => {
    try {
      const changeReservationStatusGql = gql`
        mutation changeReservationStatus($id: ID!) {
          markReservation${status}(id: $id) {
            id, status
          }
        }
      `;
      await apolloClient.mutate({
        mutation: changeReservationStatusGql,
        variables: { id },
        refetchQueries: [{ query: getStatsGql }],
      });
    } catch (err) {
      this.setState({ error: parseGqlError(err) });
    }
  }
  removeReservation = (id) => {
    this.setState({ showDeleteDialog: true, reservationToDelete: { id } })
  }
  confirmDelete = async () => {
    const { history } = this.props;
    const { id } = this.state.reservationToDelete;
    this.setState({ showDeleteDialog: false, reservationToDelete: '' });
    try {
      const removeReservationGql = gql`
        mutation removeReservation($id: ID!) {
          removeReservation(id: $id) {
            id, datetime
          }
        }
      `;
      await apolloClient.mutate({
        mutation: removeReservationGql,
        variables: { id },
        refetchQueries: [{ query: getStatsGql }],
        update: (proxy, { data: { removeReservation } }) => {
          const { getReservations } = proxy.readQuery({ query: getReservationsGql, variables: { date: getOnlyDate(removeReservation.datetime) } });
          getReservations.splice(getReservations.findIndex(r => r.id === id), 1);
          proxy.writeQuery({ query: getReservationsGql, variables: { date: getOnlyDate(removeReservation.datetime) }, data: { getReservations } });
        }
      });
      history.push('/reservations/list');
    } catch (err) {
      this.setState({ error: parseGqlError(err) });
    }
  }
  cancelDelete = () => {
    this.setState({ showDeleteDialog: false, reservationToDelete: '' })
  }
  render() {
    const { classes, history, match } = this.props;
    return (
      <Query query={getReservationGql} variables={{ id: match.params.id }}>
        {({ loading, error, data: { getReservation: reservation, getRestaurant: restaurant } = {} }) => {
          if (loading) return (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          );
          if (error) return <ErrorMessage error={parseGqlError(error)} />;
          if (!reservation) return <Redirect to="/reservation/edit" />
          return (
            <>
              <div className={classes.actionsBarStyle}>
                <Tooltip title="Back to Reservations"><IconButton onClick={() => history.push('/reservations/list')}>
                  <NavigateBeforeIcon fontSize="small" />
                </IconButton></Tooltip>
                {reservation.status !== 'ARRIVED' &&
                  <Tooltip title="Reservation got arrived successfully"><IconButton onClick={() => this.changeReservationStatus(reservation.id, 'Arrived')}>
                    <CheckIcon fontSize="small" />
                  </IconButton></Tooltip>}
                {reservation.status !== 'CANCELED' &&
                  <Tooltip title="Cancel this reservation"><IconButton onClick={() => this.changeReservationStatus(reservation.id, 'Canceled')}>
                    <CloseIcon fontSize="small" />
                  </IconButton></Tooltip>}
                <Tooltip title="Edit Reservation"><IconButton onClick={() => history.push(`/reservations/edit/${reservation.id}`)}>
                  <EditIcon fontSize="small" />
                </IconButton></Tooltip>
                {reservation.status === null &&
                  <Tooltip title="Delete Reservation"><IconButton onClick={() => this.removeReservation(reservation.id)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton></Tooltip>}
              </div>
              <Spacer />
              <Divider />
              <Spacer />
              <Typography variant="h6">Summary</Typography>
              <Spacer />
              <Paper className={classes.card}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Reservation #
                        </TableCell>
                      <TableCell>
                        {reservation.id}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Date Time
                        </TableCell>
                      <TableCell>
                        {formatDateTime(reservation.datetime)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Persons
                        </TableCell>
                      <TableCell>
                        {reservation.persons}
                      </TableCell>
                    </TableRow>
                    {reservation.notes && <TableRow>
                      <TableCell>
                        Notes
                    </TableCell>
                      <TableCell>
                        {reservation.notes}
                      </TableCell>
                    </TableRow>}
                    <TableRow>
                      <TableCell>
                        Status
                        </TableCell>
                      <TableCell>
                        <ReservationStatusChip status={reservation.status} datetime={reservation.datetime} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
              <Typography variant="h6">Customer</Typography>
              <Spacer />
              <Paper className={classes.card}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Name
                    </TableCell>
                      <TableCell>
                        {reservation.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Phone
                    </TableCell>
                      <TableCell>
                        <a href={`tel:${restaurant.callingCode}${reservation.phone}`}>{restaurant.callingCode}{reservation.phone}</a>
                      </TableCell>
                    </TableRow>
                    {reservation.email && <TableRow>
                      <TableCell>
                        Email
                    </TableCell>
                      <TableCell>
                        <a href={`mailto:${reservation.email}`}>{reservation.email}</a>
                      </TableCell>
                    </TableRow>}
                  </TableBody>
                </Table>
              </Paper>
              <Dialog
                open={this.state.showDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{`Do you really want to delete reservation #${this.state.reservationToDelete.id}?`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    This will delete the entire reservation.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div className={classes.deleteActions}>
                    <Button onClick={this.cancelDelete} variant="contained">
                      No
                  </Button>
                    <Button onClick={this.confirmDelete} variant="contained" color="secondary">
                      Yes
                  </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(ViewReservations);